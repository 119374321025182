<template>
  <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="75%"
      id="resultDialog"
      :before-close="handleClose"
  >
    <div class="flex_col">
      <!--生成结果-->
      <div class="flex_row align_center main_text_size_20   main_text_color_303133 text_align_left"
           style="font-weight: bold">
        <el-button size="small" type="success" icon="el-icon-s-flag" circle></el-button>
        <span class="w5"></span>
        <span>助记口诀</span>
      </div>
      <div class="h10"></div>
      <div class="flex_col" style="padding: 10px;border-radius: 10px;background: #f7f8f9;">
        <div v-if="isShowMore" class="title_text_size_18 main_text_color_303133 padding_10"
              style="font-weight: bold">
          <div  v-for="(item,index) in predictText.slice(0,1)" :key="index">
            <div class="flex_col">
              <div class="flex_row align_center">
                <span class="flex_1">{{item.slice(1).join(',')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="title_text_size_18 main_text_color_303133 padding_10"
             style="font-weight: bold">
          <div  v-for="(item,index) in predictText" :key="index">
            <div class="flex_col">
              <div class="flex_row align_center">
                <span class="flex_1">{{item.slice(1).join(',')}}</span>
                <span class="icon_remove_predict" v-show="predictText.length>1" @click="onPredictTextRemoveClick(index)"><i class="el-icon-close"></i></span>
              </div>
            </div>
            <div  class="margin_10_0"></div>
          </div>
        </div>
        <div  class="flex_row_allcenter">
          <span @click="onShowMoreClick" class="description_text_size_12 hold_text_color_c0c4cc"><i :class="{'el-icon-caret-bottom':isShowMore,'el-icon-caret-top':!isShowMore}"></i> 查看更多</span>
        </div>
      </div>

      <div class="h20"></div>
      <!--新生成的关键词-->
      <span class="text_align_left" @click="onShowDetailClick">
        <i :class="isHideDetail?'el-icon-caret-bottom':'el-icon-caret-top'"></i>
        提示：点击下列目标，可输入自定义助记词。
      </span>
      <div class="h2"></div>
      <div :hidden="isHideDetail" style="padding: 10px;border-radius: 10px;background: #f7f8f9;">
        <div class=" flex_col" style="padding: 10px">
          <div class=" width_100 flex_row_allcenter  padding_0_10">
            <div class=" flex_1 flex_row_allcenter height_100 small_normal_text_size_13 not_main_text_color_909399  padding_10_0 flex_warp"
            >
              <div v-for="(items,index) in generateNewWords['new_words_result']" :key="index" class="flex_row ">
                <div class="flex_row align_center margin_5" :id="`newword_${index}`">
                    <el-input v-model="finalWords[index]" placeholder="请输入内容"></el-input>
                  <el-dropdown v-if="items.length>1"  @command="onFinalWordChangeClick">
                      <i  class="el-icon-caret-bottom el-icon--right"></i>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="item in items" :key="item" :command="{index,item}">{{ item }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div v-if="items.length>1" class="w10"></div>
                </div>
              </div>
            </div>
            <el-divider direction="vertical"></el-divider>
            <el-button :disabled="isLoading"
                       size="mini"
                       type="danger"
                       round
                       @click="onGenerateNewSentenceClick">再次生成
            </el-button>
          </div>
        </div>
      </div>
      <div :hidden="isHideDetail" class="h20"></div>
      <div :hidden="isHideDetail" style="padding: 10px;border-radius: 10px;background: #f7f8f9;">
        <div class=" flex_col" style="padding: 10px">
          <div class=" width_100 flex_row_allcenter padding_0_10">
            <div class=" flex_1 flex_row_allcenter height_100 small_normal_text_size_13 not_main_text_color_909399  padding_10_0 overflow_auto"
            >
              <div v-for="(keywords,p_index) in finalSelectedKeyWordsList" :key="p_index" class="flex_row ">
                <div v-for="(keyword,index) in keywords.keywords" :key="keyword.keyword"
                     class="flex_row ">

                  <div  :id="`keyword_${p_index}_${index}`">
                    <el-button
                        v-for="(text,s_index) in keyword.keyword.split('')"
                        :key="text"
                        size="small"
                        :type="keyword.highlight === s_index?'primary':''"
                        :plain="keyword.highlight === s_index"
                        :style="{marginLeft:'0',border:'none'}"
                    >
                      {{ text }}
                    </el-button>
                  </div>

                  <span class="w5"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h20"></div>
      <!--原内容-->
      <div class="flex_col" style="padding: 10px;border-radius: 10px;background: #f7f8f9;">
        <div class="flex_row flex_warp flex_1 overflow_auto padding_10"
             style="max-height: 150px;padding-bottom: 0">
          <div class="flex_row"
               v-for="(results,p_index) in keywordsResult['results']"
               :key="p_index"
               style="height: 24px;margin-bottom: 10px">
            <div class="word_button" v-for="(item,index) in results['text_array']" :key="index">
              <el-button
                  :id="`textword_${p_index}_${index}`"
                  size="medium"
                  :style="{marginLeft:'1px',border:'none',padding:'2px !important'}"
                  :type="results['keywords_index_array'][index]===1?'primary':''"
                  plain>
                {{ item }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="h20"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" round @click="saveImageToLocal">保 存 截 图</el-button>
        <el-button type="primary" disabled round @click="saveImageToLocal">保 存 到 APP(开发中)</el-button>
      </span>
    </div>
  </el-dialog>
</template>
<script>
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import LeaderLine from 'leader-line-new'

export default {
  name: 'ResultDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    keywordsResult: {
      type: Object,
      required: true,
      default: {
        results: [],
      },
    },
    generateNewWords: {
      type: Object,
      required: true,
      default: {},
    },
    predictText: {
      type: Array,
      required: true,
      default: [],
    },
  },
  computed: {},
  watch: {
    generateNewWords(val) {
      this.isShowMore = true
      this.finalWords = [];
      this.generateNewWords['new_words_result'].forEach(item => {
        this.finalWords.push(item[0]);
      });
      this.$set(this, 'finalSelectedKeyWordsList', this.generateNewWords['final_selected_keywords']);
    },
  },
  data() {
    return {
      isLoading: false,
      finalWords: [],
      finalSelectedKeyWordsList: [],
      allLines: [],
      drawedKeywords:[],
      isHideDetail: false,
      dialogRef: null,
      isDrawingLine: false,
      isShowMore:true
    };
  },
  mounted() {
    console.log('ResultDialog mounted');
  },
  beforeDestroy() {
  },
  destroyed() {
    this.doHideAllLines()
    console.log('ResultDialog destroyed');
  },
  methods: {
    handleScroll () {
      this.doShowAllLines()
    },
    // 点击切换最终词
    onFinalWordChangeClick(data) {
      this.finalWords.splice(data.index, 1, data.item);
    },
    // 点击生成新句子
    onGenerateNewSentenceClick() {
      this.removeAllLines();
      this.$emit('onGenerateNewSentenceClick', this.finalWords);
    },
    handleClose() {
      this.removeAllLines();
      this.$emit('onCloseClick');
    },

    saveImageToLocal() {

      this.$nextTick(() => {
        domtoimage.toJpeg(document.getElementById('resultDialog'), { quality: 1.0 })
        .then(function (dataUrl) {
          var link = document.createElement('a');
          link.download = '助记口诀.jpeg';
          link.href = dataUrl;
          link.click();
        });


        //const ImageDiv = this.dialogRef;
        //使用html2canvas把界面内容生成图片
        //html2canvas(ImageDiv).then(res => {
        //  var dataUrl = res.toDataURL('image/jpeg', 1.0);//图片转为下载路径
        //  var a = document.createElement('a');//创建a标签
        //  a.href = dataUrl;
        //  a.download = '助记口诀.png';//设置图片名称
        //  a.click();
        //});
      });
    },
     onShowDetailClick() {
      this.isHideDetail = !this.isHideDetail;
      this.drawPipline()

    },
    onShowMoreClick() {
      this.isShowMore = !this.isShowMore
    },
    async drawPipline() {
      this.removeAllLines()
      if (!this.isHideDetail) {
        // 画图
        await this.drawLeaderLineForKeywords();
        await this.waitDrawingFinish();
        await this.drawLeaderLineForNewwords();
      }
    },
    async drawLeaderLineForKeywords() {
      this.isDrawingLine = true;
      this.drawedKeywords = []
      // keyword 只有一层,需要单独处理
      for (const p_index in this.keywordsResult.results) {
        // keywords_array start end
        const keywords = this.keywordsResult.results[p_index]['keywords_array'];
        for (const index in keywords) {
          await this.delay();
          this.drawLeaderLine(`textword_${p_index}_${keywords[index]['start']}`, `keyword_${p_index}_${index}`, 3);
          this.drawedKeywords.push(`keyword_${p_index}_${index}`)
        }
      }
      this.isDrawingLine = false;
    },
    async drawLeaderLineForNewwords() {
      let _start = 0;
      for (const index in this.generateNewWords['pairs']) {
        const pair = this.generateNewWords['pairs'][index];
        await this.delay();
        for (const s_index in pair) {
          this.drawLeaderLine(this.drawedKeywords[_start], `newword_${index}`, 2);
          _start = _start + 1;
        }
      }
    },
    // 画线
    drawLeaderLine(start, end, size = 2) {
      console.log(start, end);
      const ll = new LeaderLine(
          document.getElementById(start),
          document.getElementById(end),
          {
            size: size,
            color:'rgba(41,128,185,0.25)',
            startPlugColor: 'rgba(41,128,185,0.25)',
            endPlugColor: 'rgba(41,128,185,0.25)',
            gradient: true,
            startSocket: 'top',
            endSocket: 'bottom',
            startPlug: 'disc',
            endPlug: 'disc',
            showEffectName: 'draw',
            hide: true,
            dash: {animation: true},
          },
      );
      this.allLines.push(ll);
      ll.show('draw');
    },
    // 删除线
    removeAllLines() {
      for (const ll of this.allLines) {
        ll.remove();
      }
      this.allLines = [];
    },
    // 隐藏线
    doHideAllLines() {
      setTimeout(() => {
        for (const ll of this.allLines) {
          ll.hide("none")
        }
      }, 0)
    },
    // 展示线
    doShowAllLines() {
      setTimeout(() => {
        for (const ll of this.allLines) {
          ll.show("fade")
          ll.position()
        }
      }, 100)
    },
    delay(_time = 250) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, _time);
      });
    },
    waitDrawingFinish() {
      return new Promise((resolve) => {
        const _int = setInterval(() => {
          if (!this.isDrawingLine) {
            clearInterval(_int);
            resolve();
          }
        }, 300);
      });
    },
    onPredictTextRemoveClick(index) {
      this.predictText.splice(index,1)
    }
  },
}
;
</script>
<style lang="scss" scoped>

/deep/ .el-input__inner{
  text-align: center;
  }

.word_button {
  .el-button {
    background: none;
  }
}

.icon_remove_predict:hover{
  color: #409EFF;
}
</style>