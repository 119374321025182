import Vue from 'vue';
import store from './store'
import router from './router'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';

import vConsole from 'vconsole';

import animated from "animate.css"
Vue.use(animated)

const _vConsole = new vConsole();

import App from './App.vue';

Vue.use(Antd);
Vue.use(ElementUI);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
