import Vue         from 'vue'
import VueRouter   from 'vue-router'
import Home        from '../views/Home.vue'
import ProcessText from '@/views/ProcessText';
import Main        from '@/views/Main';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/processText',
    name: 'ProcessText',
    component: ProcessText
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router