<template>
  <a-layout id="components-layout-demo-top" class="height_100 flex_col">
    <a-layout-header style="text-align: center">
      <span style="color: white"> 中文文本分离工具 V1.0.1_Alpha</span>
    </a-layout-header>
    <a-layout-content style="padding: 0 50px;" class="flex_col flex_1">
      <div class="padding_15 flex_row align_center">
        <span class="w10"></span>
        <div style="width: 250px;">
          <a-form :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
            <a-form-item label="分段标记(规则)" style="margin-bottom: 0">
              <a-input v-model="default_sep" default-value="默认[SEP]"/>
            </a-form-item>
          </a-form>
        </div>
        <div style="width: 200px;">
          <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
            <a-form-item label="字符集" style="margin-bottom: 0">
              <a-input v-model="default_encoding" default-value="默认utf-8"/>
            </a-form-item>
          </a-form>
        </div>
        <div class="w20"></div>
        <a-upload
            accept=".txt"
            :showUploadList="false"
            :transform-file="transformFile"
        >
          <a-button>
            <a-icon type="cloud-upload"/>
            上传TXT文件
          </a-button>
        </a-upload>
        <span class="w20"></span>
        <a-tag v-if="localFile" closable>{{ localFile.name || '' }}</a-tag>
        <span class="flex_1"></span>

        <a-popover title="快捷键说明 : Windows系统使用CTRL键,Mac系统使用Command键" placement="topLeft" arrow-point-at-center>
          <template slot="content">
            <div class="flex_col">
              <a-tag color="blue">CTRL+方向键 : 上一条/下一条</a-tag>
              <div class="h5"></div>
              <a-tag color="blue">CTRL+ENTER : 标记完成</a-tag>
              <div class="h5"></div>
              <a-tag color="blue">CTRL+C : 复制本条结果</a-tag>
              <div class="h5"></div>
              <a-tag color="blue">CTRL+1 : 上一个标题分离规则</a-tag>
              <div class="h5"></div>
              <a-tag color="blue">CTRL+2 : 下一个标题分离规则</a-tag>
              <div class="h5"></div>
              <a-tag color="blue">CTRL+3 : 上一个内容分离规则</a-tag>
              <div class="h5"></div>
              <a-tag color="blue">CTRL+4 : 下一个内容分离规则</a-tag>
              <div class="h5"></div>
            </div>
          </template>
          <a-button type="dashed">
            <a-icon type="calculator"/>
            快捷键
          </a-button>
        </a-popover>


        <span class="w10"></span>
        <a-button type="primary" @click="onExportClick">
          <a-icon type="line-chart"/>
          导出数据
        </a-button>
      </div>
      <div class="flex_row" :style="{ background: '#fff', padding: '24px', minHeight: '280px',flex:1 }">
        <!--原始文本-->
        <div class="flex_1" style="text-align: left">
          <a-card class="original_card" title="1. 待处理文本">
            <div class="flex_1 flex_row align_center" slot="extra">
              <div class="w10"></div>
              <div class="w20"></div>
              <a-progress
                  :stroke-color="{
        from: '#108ee9',
        to: '#87d068',
      }"
                  :percent="percent"
                  status="active"
              />
              <div class="w20"></div>
              <div class="w10"></div>

              <a-button :disabled="!alteredData" @click="onFinishButtonClick" type="primary">此项完成
              </a-button>
            </div>
            <div v-if="originalData">
              <div class="flex_row align_center">
                <div class="flex_row align_center" style="width: 150px;">
                  <span class="w10"></span>
                  <span v-if="!originalData"><a-tag color="#f50"><a-icon type="close-circle"/> 无数据</a-tag></span>
                  <span v-else-if="currentPageFinish"><a-tag color="#87d068"><a-icon
                      type="check-circle"/> 已完成</a-tag></span>
                  <span v-else><a-tag color="#2db7f5"><a-icon type="edit"/> 处理中</a-tag></span>
                </div>
                <span class="flex_1"></span>
                <a-pagination
                    @change="onPageChange"
                    simple
                    :total="originalDatas.length"
                    :page-size="1"
                    :current="currentPageIndex"
                    :default-current="1"
                />
                <span class="flex_1"></span>


                <div style="width: 75px;">
                  <a-button slot="extra" @click="onFormatAlteredDataClick" type="dashed">格式化</a-button>
                </div>
                <div class="w10"></div>
                <a-popover title="" placement="topLeft" arrow-point-at-center>
                  <template slot="content">
                    <div style="width: 500px;word-break: break-word">
                      {{ originalData }}
                    </div>
                  </template>
                  <div style="width: 75px;text-align: right">
                    <a-button :disabled="!alteredData" type="link">
                      原文本
                    </a-button>
                  </div>
                </a-popover>

              </div>
              <div class="h10"></div>
              <!--textarea-->
              <a-textarea style="font-size: 18px;letter-spacing: 5px;" ref="alteredDataInput" :auto-size="{ minRows: 10, maxRows: 10 }" v-model="alteredData"
                          @change="onAlteredDataChange"/>
              <!--/textarea-->
              <a-divider dashed>可选配置</a-divider>
              <div class="options flex_col">
                <a-checkbox-group :defaultValue="formatOptions" @change="onFormatOptionsChange">
                  <a-row>
                    <a-col :span="8">
                      <a-checkbox value="A">
                        是否自动格式化
                      </a-checkbox>
                    </a-col>
                    <a-col :span="8">
                      <a-checkbox value="B">
                        是否排除文本开头非中文部分(第一个字必须为中文)
                      </a-checkbox>
                    </a-col>
                    <a-col :span="8">
                      <a-checkbox value="C">
                        是否将中文符号全部转换成英文符号
                      </a-checkbox>
                    </a-col>
                  </a-row>
                  <div class="h10"></div>
                  <a-row>
                    <a-col :span="8">
                      <a-checkbox value="D">
                        是否需要分离最后一项
                      </a-checkbox>
                    </a-col>
                    <a-col :span="8">
                      <a-checkbox value="E">
                        是否去除括号及内容
                      </a-checkbox>
                    </a-col>
                    <a-col :span="8">
                      <a-checkbox value="F">
                        是否删除最后的标点符号
                      </a-checkbox>
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </div>
            </div>
            <a-result v-else status="warning" title="暂无数据，请先上传待处理的数据文件，仅支持txt格式!">
              <template #extra>

                <a-upload
                    key="console"
                    accept=".txt"
                    :showUploadList="false"
                    :transform-file="transformFile"
                >
                  <a-button type="primary">
                    <a-icon type="cloud-upload"/>
                    上传TXT文件
                  </a-button>
                </a-upload>
              </template>
            </a-result>
          </a-card>
        </div>
        <div class="w20"></div>
        <!--标题内容分离-->
        <div v-if="originalData" style="width: 500px;;text-align: left">
          <a-card title="2. 标题内容分离" style="width:100%;height: 100%;overflow: auto">
            <div class="flex_row align_center" slot="extra">
              <a-switch checked-children="自动分离" un-checked-children="手动分离" default-checked
                        :checked="booleanAutoSeparete" @change="onBooleanAutoSepareteChange"/>
              <!--<div class="w10"></div>-->
              <!--<a-button :disabled="!alteredData" type="primary" @click="onCopyResultClick">复制结果(Ctrl+C)</a-button>-->
            </div>

            <!--<a-divider dashed>标题分离</a-divider>-->
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
              <a-form-item label="分离方式" style="margin-bottom: 0;">
                <a-dropdown>
                  <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                    <span
                        v-if="selectedSepareteTitleTag.name">{{
                        selectedSepareteTitleTag.name
                      }} : {{ selectedSepareteTitleTag.demo }}</span>
                    <span v-else>选择标题分离依据</span>
                    <a-icon type="down"/>
                  </a>
                  <a-menu slot="overlay">
                    <a-menu-item v-for="(item,index) in separeteTitleTags" :key="index"
                                 @click="onSelectedTitleSepareteTypeClick(item)">
                      {{ item.name }} : {{ item.tag.toString() }}
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </a-form-item>
              <a-form-item label="分离标题" style="margin-bottom: 0;">
                <span>{{ newTitle }}</span>
              </a-form-item>
              <a-form-item label="分离内容">
                <span>{{ newContent }}</span>
              </a-form-item>
              <a-divider dashed>3. 内容分离</a-divider>
              <a-form-item label="分离方式" style="margin-bottom: 0;">
                <a-dropdown>
                  <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                    <span
                        v-if="selectedSepareteContentTag.name">{{
                        selectedSepareteContentTag.name
                      }} : {{ selectedSepareteContentTag.demo }}</span>
                    <span v-else>选择内容分离依据</span>
                    <a-icon type="down"/>
                  </a>
                  <a-menu slot="overlay">
                    <a-menu-item v-for="(item,index) in separeteContentTags" :key="index"
                                 @click="onSelectedContentSepareteTypeClick(item)">
                      {{ item.name }} : {{ item.demo }}
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </a-form-item>
              <a-form-item label="分离内容" style="margin-bottom: 0;">
                <div v-if="newContentArray.length">
                  <div v-for="(item,index) in newContentArray" :key="index">
                    <div class="flex_row align_center">
                      <span><a-tag>{{ index + 1 }}</a-tag>{{ item }}</span>
                      <span class="w5"></span>
                      <span class="flex_1"></span>
                      <a-icon @click="onRemoveNewContentItemClick(index)" type="close-circle"/>
                    </div>
                    <a-divider style="margin: 0;"/>
                  </div>
                </div>
                <div v-else>
                  <a-empty/>
                </div>
              </a-form-item>

            </a-form>
          </a-card>
        </div>
      </div>
    </a-layout-content>
    <!-- <a-layout-footer style="text-align: center">
       NER SEPARETE TEXT TOOLS ©2022 Created by CUIWANG
     </a-layout-footer>-->
  </a-layout>
</template>
<style lang="scss">
@import "../assets/globals";

#components-layout-demo-top .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}
</style>


<script>
// @ is an alias to /src

import {EnglishPunction, replaceChinese} from '@/libs/punctuation-conversion';
import {trim} from 'core-js/internals/string-trim';
import FileSaver from 'file-saver';

export default {
  name: 'Home',
  data() {
    return {
      // 文件默认字符集
      default_encoding: 'utf-8',
      // 文件分隔符
      default_sep: '[SEP]',
      // 进度指示
      percent:0,
      // 上传的文件对象
      localFile: null,
      //从上传的文件中分割出的原始数据列表
      originalDatas: [],
      //当前页面
      currentPageIndex: 1,
      currentPageFinish: false,
      originalData: '',//当前展示的原始文本
      alteredData: '',//当前展示的处理后文本
      formatOptions: ['A', 'B', 'C', 'D', 'E', 'F'],
      booleanAutoSeparete: true,//是否自动分离
      // 标题分离标志
      separeteTitleTags: [
        {name: '冒号', demo: ':：', tag: ['[:：]']},
        {name: '问号', demo: '?？', tag: ['[?？]']},
        {name: '是/是指', demo: '是/是指', tag: ['(?:是指|是)']},
      ],
      // 选中的标题分离索引
      selectedSepareteTitleTagIndex: 0,
      // 选中的标题分离对象
      selectedSepareteTitleTag: {
        name: '',
        tag: [],
      },
      // 内容分离标志
      separeteContentTags: [
        {name: '不分', demo: '原分离内容', tag: []},
        {name: '逗号', demo: ',，', tag: ['[,，]']},
        {name: '句号', demo: '.。', tag: ['[.。]']},
        {name: '顿号', demo: '、', tag: ['[、]']},
        {name: '分号', demo: '；;', tag: ['[；;]']},
        {name: '数字+顿号', demo: '1、 2、...', tag: ['(?:１、|２、|３、|４、|５、|６、|７、|８、|９、|1、|2、|3、|4、|5、|6、|7、|8、|9、)']},
        {name: '数字+逗号', demo: '1, 2,...', tag: ['(?:１,|２,|３,|４,|５,|６,|７,|８,|９,|1,|2,|3,|4,|5,|6,|7,|8,|9,)']},
        {
          name: '数字+句号',
          demo: '1． 2．...',
          tag: ['(?:１\\.|２\\.|３\\.|４\\.|５\\.|６\\.|７\\.|８\\.|９\\.|1\\.|2\\.|3\\.|4\\.|5\\.|6\\.|7\\.|8\\.|9\\.)'],
        },
        {
          name: '数字+括号',
          demo: '1） 2）...',
          tag: ['(?:１\\)|２\\)|３\\)|４\\)|５\\)|６\\)|７\\)|８\\)|９\\)|1\\)|2\\)|3\\)|4\\)|5\\)|6\\)|7\\)|8\\)|9\\))'],
        },
        {name: '数字序号', demo: '① ②...', tag: ['(?:①|②|③|④|⑤|⑥|⑦|⑧|⑨)']},
        {
          name: '中文序号',
          demo: '㈠　㈡...',
          tag: ['(?:㈠|㈡|㈢|㈣|㈤|㈥|㈦|㈧|㈨|\\(一\\)|\\(二\\)|\\(三\\)|\\(四\\)|\\(五\\)|\\(六\\)|\\(七\\)|\\(八\\)|\\(九\\))'],
        },
        {
          name: '括号序号',
          demo: '⑴　⑵...',
          tag: ['(?:⑴|⑵|⑶|⑷|⑸|⑹|⑺|⑻|⑼|\\(１\\)|\\(２\\)|\\(３\\)|\\(４\\)|\\(５\\)|\\(６\\)|\\(７\\)|\\(８\\)|\\(９\\)|\\(1\\)|\\(2\\)|\\(3\\)|\\(4\\)|\\(5\\)|\\(6\\)|\\(7\\)|\\(8\\)|\\(9\\))'],
        },
        {name: '罗马数字', demo: 'Ⅰ　Ⅱ或ⅰ　ⅱ．．．', tag: ['(?:Ⅰ|Ⅱ|Ⅲ|Ⅳ|Ⅴ|Ⅵ|Ⅶ|Ⅷ|Ⅸ|ⅰ|ⅱ|ⅲ|ⅳ|ⅴ|ⅵ|ⅶ|ⅷ|ⅸ)']},
        {name: '实底数字序号', demo: '❶　❷...', tag: ['(?:❶|❷|❸|❹|❺|❻|❼|❽|❾)']},
      ],
      // 选中的内容分离索引
      selectedSepareteContentTagIndex: 0,
      // 选中的内容分离对象
      selectedSepareteContentTag: {
        name: '',
        tag: [],
      },
      // 末尾内容分离标志 (xxx和zzz 应该分离成 xxx,zzz)
      endAndTags: '(?:和|与|以及|及)',
      // 分离后的标题
      newTitle: '',
      // 分离后的内容
      newContent: '',
      // 分离内容后的数组
      newContentArray: [],
      // 完成数据,待导出数据,key是索引位置
      finishDatasMap: {},

    };
  },
  components: {},
  mounted() {
    this.initEvent();
    this.initData();
  },
  methods: {
    // ────────────────────────── init ──────────────────────────
    initEvent() {
      // 按键事件监听
      document.onkeydown = (e) => {
        //事件对象兼容
        let evn = e || event || window.event;
        let key = evn.keyCode || evn.which || evn.charCode;
        //console.log(evn);
        //console.log(key);
        if ((evn.ctrlKey || evn.metaKey) && (key === 37 || key === 38)) {
          // 按下方向左/上键
          //this.decrease()
          if (this.originalDatas.length > 0) {
            this.onPageChange(this.currentPageIndex <= 1 ? 1 : this.currentPageIndex - 1);
          }

        }
        else if ((evn.ctrlKey || evn.metaKey) && (key === 39 || key === 40)) {
          //按下方向右/下键
          if (this.originalDatas.length > 0) {
            this.onPageChange(this.currentPageIndex >= this.originalDatas.length ? this.originalDatas.length
                : this.currentPageIndex + 1);
          }
        }
        else if (((evn.ctrlKey || evn.metaKey) && key === 13)) {
          // 按下ctrl+enter
          // 取消输入框焦点,防止意外输入
          this.$refs.alteredDataInput.blur();
          this.onFinishButtonClick();
        }
        else if (((evn.ctrlKey || evn.metaKey) && key === 67)) {
          // 按下CTRL+C/Command+C
          // 取消输入框焦点,防止意外输入
          this.$refs.alteredDataInput.blur();
          this.onCopyResultClick();
        }
        /* else if (evn && key === 88) {
           // 按下x键,清空
           this.alteredData = '';
           this.newTitle = '';
           this.newContent = '';
           this.newContentArray = [];
         }*/
        else if ((evn.ctrlKey || evn.metaKey) && key === 49) {
          // 取消输入框焦点,防止意外输入
          this.$refs.alteredDataInput.blur();
          // 按下1上一项
          this.selectedSepareteTitleTagIndex = this.selectedSepareteTitleTagIndex - 1;
          if (this.selectedSepareteTitleTagIndex < 0) {
            this.selectedSepareteTitleTagIndex = this.separeteTitleTags.length - 1;
          }
          this.selectedSepareteTitleTag = this.separeteTitleTags[this.selectedSepareteTitleTagIndex];
          this.doSeparete(true);
        }
        else if ((evn.ctrlKey || evn.metaKey) && key === 50) {
          // 取消输入框焦点,防止意外输入
          this.$refs.alteredDataInput.blur();
          // 按下数字2,下一项
          this.selectedSepareteTitleTagIndex = this.selectedSepareteTitleTagIndex + 1;
          if (this.selectedSepareteTitleTagIndex > this.separeteTitleTags.length - 1) {
            this.selectedSepareteTitleTagIndex = 0;
          }
          this.selectedSepareteTitleTag = this.separeteTitleTags[this.selectedSepareteTitleTagIndex];
          this.doSeparete(true);
        }
        else if ((evn.ctrlKey || evn.metaKey) && key === 51) {
          // 取消输入框焦点,防止意外输入
          this.$refs.alteredDataInput.blur();
          // 按下数字3,上一项
          this.selectedSepareteContentTagIndex = this.selectedSepareteContentTagIndex + 1;
          if (this.selectedSepareteContentTagIndex >= this.selectedSepareteContentTag.length) {
            this.selectedSepareteContentTagIndex = 0;
          }
          this.selectedSepareteContentTag = this.separeteContentTags[this.selectedSepareteContentTagIndex];
          this.doSepareteContent(true);
        }
        else if ((evn.ctrlKey || evn.metaKey) && key === 52) {
          // 取消输入框焦点,防止意外输入
          this.$refs.alteredDataInput.blur();
          // 按下数字4,下一项
          this.selectedSepareteContentTagIndex = this.selectedSepareteContentTagIndex - 1;
          if (this.selectedSepareteContentTagIndex < 0) {
            this.selectedSepareteContentTagIndex = this.separeteContentTags.length - 1;
          }
          this.selectedSepareteContentTag = this.separeteContentTags[this.selectedSepareteContentTagIndex];
          this.doSepareteContent(true);
        }
      };
    },
    initData() {
      this.selectedSepareteTitleTag = this.separeteTitleTags[this.selectedSepareteTitleTagIndex];
      this.selectedSepareteContentTag = this.separeteContentTags[this.selectedSepareteContentTagIndex];
    },
    // ────────────────────────── methods ──────────────────────────
    // 输入数据变化
    onAlteredDataChange() {
      if (this.alteredData) {
        // 判断是否已经保存,重放进数据源
        if (`${this.currentPageIndex}` in this.finishDatasMap) {
          const data = this.finishDatasMap[`${this.currentPageIndex}`];
          this.newTitle = data.title;
          this.newContent = data.content;
          this.newContentArray = data.contentArray;
          // 标记已完成
          this.currentPageFinish = true;
        }
        else {
          this.newTitle = '';
          this.newContent = '';
          this.newContentArray = [];
          // 标记未完成
          this.currentPageFinish = false;

          if (!this.originalData) {
            this.originalData = JSON.parse(JSON.stringify(this.alteredData));
          }
          // 是否自动格式化,触发格式化动作
          if (this.formatOptions.includes('A')) {
            this.onFormatAlteredDataClick();
          }
        }

      }
    },
    // 执行分离标题和内容
    doSeparete(boolenNotUserSelected = true) {
      if (this.alteredData) {
        const _data = this.extractTitleAndContent(this.alteredData, boolenNotUserSelected);
        if (_data.length > 1) {
          this.newTitle = _data[0];
          this.newContent = _data[1];
          // 分离子内容
          this.doSepareteContent(boolenNotUserSelected);
        }
      }
    },
    // 分离标题和内容
    extractTitleAndContent(_data, boolenNotUserSelected = true) {
      let result = [];
      // 自动分离
      if (this.booleanAutoSeparete && boolenNotUserSelected) {
        const _separeteTitleTags = JSON.parse(JSON.stringify(this.separeteTitleTags)).reverse();
        // 遍历分离规则
        for (let i = 0; i < _separeteTitleTags.length; i++) {
          const _selectedSepareteTitleTag = _separeteTitleTags[i];
          if (_selectedSepareteTitleTag.tag.length > 0) {
            const _tag = new RegExp(_selectedSepareteTitleTag.tag[0], 'g');
            if (_tag.test(_data)) {
              const _split = _data.split(_tag, 2);
              if (_split.length === 2) {
                let _title = _split[0];
                let _content = _split[1];

                // 判断是否去掉句尾标点符号
                if (this.formatOptions.includes('F')) {
                  const _punction = _title.substring(_title.length - 1);
                  if (EnglishPunction.includes(_punction)) {
                    _title = _title.substring(0, _title.length - 1);
                  }
                  const _punction2 = _content.substring(_content.length - 1);
                  if (EnglishPunction.includes(_punction2)) {
                    _content = _content.substring(0, _content.length - 1);
                  }
                }
                result.push(_title);
                result.push(_content);

                this.selectedSepareteTitleTagIndex = i;
                this.selectedSepareteTitleTag = _selectedSepareteTitleTag;
                break;
              }
            }
          }
        }
      }
      // 手动分离
      else {
        if (this.selectedSepareteTitleTag.tag.length > 0) {
          const _tag = new RegExp(this.selectedSepareteTitleTag.tag[0], 'g');
          if (_tag.test(_data)) {
            const _split = _data.split(_tag, 2);
            if (_split.length === 2) {
              let _title = _split[0];
              let _content = _split[1];

              // 判断是否去掉句尾标点符号
              if (this.formatOptions.includes('F')) {
                const _punction = _title.substring(_title.length - 1);
                if (EnglishPunction.includes(_punction)) {
                  _title = _title.substring(0, _title.length - 1);
                }
                const _punction2 = _content.substring(_content.length - 1);
                if (EnglishPunction.includes(_punction2)) {
                  _content = _content.substring(0, _content.length - 1);
                }
              }
              result.push(_title);
              result.push(_content);
            }
          }
        }
      }
      return result;
    },
    // 抽取子内容
    extractNewContent(_data, boolenNotUserSelected = true) {
      let result = [];
      // 是否自动分离
      if (this.booleanAutoSeparete && boolenNotUserSelected) {
        const _separeteContentTags = JSON.parse(JSON.stringify(this.separeteContentTags)).reverse();
        // 遍历分离规则
        for (let i = 0; i < _separeteContentTags.length; i++) {
          const _separeteContentTag = _separeteContentTags[i];
          if (_separeteContentTag.tag.length === 1) {
            const _tag = new RegExp(_separeteContentTag.tag[0], 'g');
            if (_tag.test(_data)) {
              result = _data.split(_tag);
              this.selectedSepareteContentTagIndex = i;
              this.selectedSepareteContentTag = _separeteContentTag;
              break;
            }
          }
        }
        // 如果无法自动分离(规则全部无效),就获取原始数据
        if (result.length === 0) {
          // 按原内容输出
          result.push(_data);
        }
      }
      // 手动分离
      else {
        if (this.selectedSepareteContentTag && this.selectedSepareteContentTag.tag.length === 0) {
          // 按原内容输出
          result.push(_data);
        }
        else if (this.selectedSepareteContentTag && this.selectedSepareteContentTag.tag.length === 1) {
          const _tag = new RegExp(this.selectedSepareteContentTag.tag[0], 'g');
          if (_tag.test(_data)) {
            result = _data.split(_tag);
          }
        }
      }

      let data = result.filter(value => {
        return !!value;
      });

      // 是否分离最后一项,通过关键字 endAndTags:[]
      if (this.formatOptions.includes('D')) {
        const _pop = data.pop();
        if (_pop) {
          const _tag = new RegExp(this.endAndTags, 'g');
          result = _pop.split(_tag);
          data = data.concat(result);
        }
      }

      // 判断是否去掉句尾标点符号
      if (this.formatOptions.includes('F')) {
        data = data.map(value => {

          const _punction = value.substring(value.length - 1);
          if (EnglishPunction.includes(_punction)) {
            value = value.substring(0, value.length - 1);
          }
          return value;
        });
      }
      return data;
    },
    // 选择完标题内容分离依据
    onSelectedTitleSepareteTypeClick(e) {
      this.selectedSepareteTitleTag = e;
      this.doSeparete(false);
    },
    // 选择完内容分离依据
    onSelectedContentSepareteTypeClick(e) {
      this.selectedSepareteContentTag = e;
      this.doSepareteContent(false);
    },
    // 分离子内容
    doSepareteContent(boolenNotUserSelected = true) {
      if (this.newContent) {
        this.newContentArray = this.extractNewContent(this.newContent, boolenNotUserSelected);
      }
    },
    // 去掉原文本中的空格,将英文符号转成中文符号.
    onFormatAlteredDataClick() {
      if (this.alteredData) {
        let resultStr = trim(this.alteredData); //去掉首尾空格
        resultStr = resultStr.replace(/\s+/g, ''); //去掉中间空格
        resultStr = resultStr.replace(/[\r\n]/g, ''); //去掉回车换行
        this.alteredData = resultStr;
        // 是否去掉非中文开头部分
        if (this.formatOptions.includes('B')) {
          this.alteredData = this.alteredData.substring(this.alteredData.search(/[\u4e00-\u9fa5]/));
        }
        // 是否将英文符号转成中文符号
        if (this.formatOptions.includes('C')) {
          this.alteredData = replaceChinese(this.alteredData);
        }
        // 是否删除内容中的圆括号及括号内容
        if (this.formatOptions.includes('E')) {
          this.alteredData = this.alteredData.replace(/\(.*\)/, '');
        }
        //this.$message.info('格式化完成');
        this.doSeparete(true); //执行分离
      }
    },
    // 格式化选项修改
    onFormatOptionsChange(e) {
      this.formatOptions = e;
      // 是否自动格式化,触发格式化动作
      if (this.formatOptions.includes('A')) {
        this.onFormatAlteredDataClick();
      }
    },
    // 点击删除不要的子内容
    onRemoveNewContentItemClick(index) {
      this.newContentArray.splice(index, 1);
    },
    // 点击复制按钮
    onCopyResultClick() {
      if (this.newTitle && this.newContentArray.length > 0) {
        const result = this.newTitle + '\t' + this.newContentArray;
        let transfer = document.createElement('input');
        document.body.appendChild(transfer);
        transfer.value = result;  // 这里表示想要复制的内容
        transfer.focus();
        transfer.select();
        if (document.execCommand('copy')) {
          document.execCommand('copy');
        }
        transfer.blur();
        console.log('复制成功');
        this.$notification['success']({
          message: '复制成功',
          description:
              '请在其他系统中,粘贴查看~',
        });
        document.body.removeChild(transfer);
      }
      else {
        this.$notification['error']({
          message: '复制失败',
          description:
              '请先进行标题和内容分离',
        });
      }
    },
    // 上传后操作文件
    transformFile(file) {
      this.localFile = file;
      return new Promise(resolve => {
        this.originalDatas = [];
        const reader = new FileReader();
        reader.readAsText(file, this.default_encoding);
        reader.onload = (e) => {
          const fileText = e.target.result.split(this.default_sep);
          fileText.forEach(text => {
            if (text) {
              this.originalDatas.push(text);
            }
          });
          if (this.originalDatas.length > 0) {
            this.alteredData = this.originalDatas[this.currentPageIndex - 1];
            // 触发格式化
            this.onAlteredDataChange();
          }
        };
      });
    },
    // 切换页面
    onPageChange(_index) {
      this.alteredData = this.originalDatas[_index - 1];
      if (this.alteredData){
      this.currentPageIndex = _index;
      this.originalData = '';
      // 触发格式化
      this.onAlteredDataChange();
      }
    },
    // 点击完成按钮事件
    onFinishButtonClick() {
      if (this.newTitle && this.newContentArray.length > 0) {
        this.finishDatasMap[`${this.currentPageIndex}`] = {
          originalData: this.originalData,
          alteredData: this.alteredData,
          title: this.newTitle,
          content: this.newContent,
          contentArray: this.newContentArray,
        };
        // 标记已完成
        this.currentPageFinish = true;
        this.$message.success('已完成');
        // 计算进度
        this.percent = this.getPercent(Object.keys(this.finishDatasMap).length,this.originalDatas.length)

        // 自动下一项
        if (this.originalDatas.length > 0 && this.currentPageIndex<this.originalDatas.length) {
          this.onPageChange(this.currentPageIndex + 1);
        }
      }
      else {
        this.$message.error('分离未完成');
      }
    },
    // 是否自动分离,修改后自动分离
    onBooleanAutoSepareteChange(checked) {
      this.booleanAutoSeparete = checked;
      if (this.booleanAutoSeparete) {
        this.doSeparete(true);
      }
    },
    // 点击导出数据
    onExportClick() {
      // this.finishDatasMap
      const data = JSON.stringify(this.finishDatasMap);
      const blob = new Blob([data], {type: ''});
      // const textStr = 'aaaaa,bbbbbbb,cccccc'
      FileSaver.saveAs(blob, 'results.json');
    },
    // 计算百分比
    getPercent(num, total) {
      num = parseFloat(num);
      total = parseFloat(total);
      if (num === 0 || total ===0){
        return 0;
      }
      if (isNaN(num) || isNaN(total)) {
        return 0;
      }
      return  Number.parseFloat((Math.round((num / total) * 10000) / 100.0).toFixed(1));
    },
  },
};
</script>
<style lang="scss">


.original_card {
  width: 100%;
  height: 100%;

  .ant-card-head-title {
    flex: none;
  }

  .ant-card-extra {
    flex: 1;
  }
}
</style>
