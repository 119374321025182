export const Cons = {
  separeteContentTags:
      [
        {
          name: '顿号',
          demo: '、',
          tag: ['[、]'],
        }, {
        name: '逗号',
        demo: ',，',
        tag: ['[,，]'],
      }, {
        name: '分号',
        demo: '；;',
        tag: ['(?:[；;])'],
      }, {
        name: '句号',
        demo: '.。',
        tag: ['(?:[.。])'],
      }, {
        name: '数字+逗号',
        demo: '1, 2,...',
        tag: ['(?:[0-9]+,)'],
      }, {
        name: '数字+句号',
        demo: '1． 2．...',
        tag: ['(?:[0-9]+\\.)'],
      }, {
        name: '数字+顿号',
        demo: '1、 2、...',
        tag: ['(?:[0-9]+、)'],
      }, {
        name: '数字+单括号',
        demo: '1） 2）...',
        tag: ['(?:[0-9]+\\))'],
      }, {
        name: '数字序号',
        demo: '① ②...',
        tag: ['(?:①|②|③|④|⑤|⑥|⑦|⑧|⑨)'],
      }, {
        name: '实底数字序号',
        demo: '❶　❷...',
        tag: ['(?:❶|❷|❸|❹|❺|❻|❼|❽|❾)'],
      }, {
        name: '罗马数字',
        demo: 'Ⅰ　Ⅱ或ⅰ　ⅱ．．．',
        tag: ['(?:Ⅰ|Ⅱ|Ⅲ|Ⅳ|Ⅴ|Ⅵ|Ⅶ|Ⅷ|Ⅸ|ⅰ|ⅱ|ⅲ|ⅳ|ⅴ|ⅵ|ⅶ|ⅷ|ⅸ)'],
      }, {
        name: '数字+方括号',
        demo: '[1] [2]...',
        tag: ['(?:\\[[0-9]+\\])'],
      }, {
        name: '数字+尖括号',
        demo: '<1> <2>...',
        tag: ['(?:\\<[0-9]+\\>)'],
      }, {
        name: '括号序号',
        demo: '⑴　⑵...',
        tag: ['(?:⑴|⑵|⑶|⑷|⑸|⑹|⑺|⑻|⑼|\\([0-9]+\\))'],
      }, {
        name: '中文序号2',
        demo: '㈠　㈡...',
        tag: ['(?:㈠|㈡|㈢|㈣|㈤|㈥|㈦|㈧|㈨|\\(一\\)|\\(二\\)|\\(三\\)|\\(四\\)|\\(五\\)|\\(六\\)|\\(七\\)|\\(八\\)|\\(九\\))'],
      }, {
        name: '中文序号0',
        demo: '一,二,...',
        tag: ['\\B[一二三四五六七八九][,.、]'],
      }, {
        name: '中文序号1',
        demo: '第一,第二,...',
        tag: ['(?:\\B第[一二三四五六七八九]|\\B其次)[,.、]'],
      }],
  tipsArray:['使用回车键,将录入的文本分成小片段！', '将文本切分成小片段，能有效提高关键词提取效果。', '每段最多抽取三个关键词。']
}