<template>
  <div class="flex_col height_100">
    <!--头部：提示-->
    <div v-if="isShowInputTipView" class="flex_col_allcenter animate__animated animate__fadeIn" style="margin: 50px 0">
      <div class="main_text_size_32 black_color bold_text">自动生成助记口诀</div>
      <div class="h15"></div>
      <div class="main_text_size_20 main_text_color_303133 ">短时间、大量记忆、快速记忆</div>
      <div class="h15"></div>
      <el-button type="primary" @click="isShowInputTipView = false">开始使用</el-button>
    </div>
    <!--中部：操作区域-->
    <div :class="{tip_container_padding:isShowInputTipView}" class="flex_1">
      <el-container class="height_100 flex_col container"
      >
        <el-header class="flex_row align_center" style="background:#2980B9">
          <span class="main_text_size_20" style="font-weight: bold;color: white">欢迎使用小口诀</span>
          <div class="flex_1"></div>
          <div v-if="!isShowInputTipView" style="cursor: pointer"
               @click="onLinkClicked('https://www.pgyer.com/xiaokj')">App & 小程序
          </div>
        </el-header>
        <el-main class="flex_col flex_1">
          <!--main header-->
          <!--main content-->
          <div class="flex_col flex_1 height_100 padding_20">
            <!--用户原始数据输入框-->
            <el-card style="height: 266px">
              <div class="flex_col animate__animated animate__fadeIn">
                <div class="user_input_view flex_col_allcenter flex_1">
                  <div class="user_input_view_main flex_row flex_1"
                       style="width: 100%;box-sizing: border-box;">
                    <!--用户输入-->
                    <div class="flex_col flex_1">
                      <div class="flex_row_allcenter" style="height: 44px;width: 100%;">
                        <span class="w10"></span>
                        <span
                          class="description_text_size_12 paragraph_text_color_c0c4cc hidden-xs-only">将文本分割成小片段，将极大提高关键词提取效果。
                        </span>
                        <span class="flex_1"></span>
                        <span v-if="separetedUserInputDataArray.length>0"
                              class="hidden-sm-only normal_text_color_606266"
                        >自动切分成
                          <span style="color: #E6A23C">{{this.separetedUserInputDataArray.length}}</span>
                          段
                        </span>
                        <el-divider direction="vertical"></el-divider>
                        <span :class="isDisabled?'hold_text_color_c0c4cc':'paragraph_text_color_c0c4cc'"
                              @click="onClearUserInputClick">
                          <i class="el-icon-delete"></i>
                          清空
                        </span>
                        <span class="w10"></span>
                      </div>
                      <el-input
                        ref="mark"
                        v-model="userInputData"
                        :disabled="isGenerateNewWords || isGenerateNewSentance"
                        :rows="4"
                        clearable
                        maxlength="200"
                        placeholder="请输入文本内容，通过回车换行分割成小片段，提升抽取效果。"
                        resize="none"
                        show-word-limit
                        type="textarea"
                        @focus="isShowInputTipView = false"
                        @input="onUserInputDataChange"
                      >
                      </el-input>
                      <div class="flex_row_allcenter" style="height: 44px;width: 100%;">
                        <span class="w10"></span>
                        <span class="flex_1"></span>
                        <span class="flex_1"></span>
                        <span
                          class="description_text_size_12 hold_text_color_c0c4cc">建议使用回车换行键(ENTER/RETURN)将文本分割成小片段。
                        </span>
                        <span class="w10"></span>
                      </div>
                      <div>
                        <el-button :disabled="userInputData.length===0 || finalSelectedKeyWordsList.length===0"
                                   :loading="isGenerateNewWords||isGenerateNewSentance"
                                   icon="el-icon-s-promotion"
                                   type="primary"
                                   @click="onGetResultButtonClick">{{isGenerateNewWords ? '正在合成关键词...' : isGenerateNewSentance ? '正在生成口诀句...' : '生成口诀句'}}
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
            <div class="h2"></div>
            <!-- 自动提取关键词 -->
            <template v-if="!isGenerateNewSentance">
              <el-card
                v-show="keywordsResult['results'].length>0"
                v-loading="isLoading"
                :body-style="{padding:'25px',flex:'1',overflow:'auto',minHeight:'141px'}"
                class="flex_col"
                style="flex:1">
                <!--内容布局-->
                <div class="keywords_content flex_col height_100">
                  <!--关键词展示-->
                  <div slot="header" class="flex_row_allcenter keywords_content_wrapper border_wrapper padding_10">
                    <span class="w5"></span>
                    <span class="small_normal_text_size_13 hold_text_color_c0c4cc">关键词 :</span>
                    <span class="w5"></span>
                    <!--关键词列表-->
                    <div class="flex_1 flex_row overflow_auto flex_warp ">
                      <div v-for="(result,p_index) in keywordsResult['results']" :key="p_index" class="flex_row">
                        <div v-for="(item,index) in result['keywords_array']"
                             :key="item.keyword" style="margin-top: 5px">
                          <el-tag
                            closable
                            effect="light"
                            style="margin-left: 5px"
                            type="primary"
                            @close="onKeywordRemoveClick(index,p_index)">
                            {{item.keyword}}
                          </el-tag>
                        </div>
                        <div class="w5"></div>
                      </div>
                    </div>
                    <span class="w10"></span>
                    <span
                      :class="keywordsResult['results'].length>0?'paragraph_text_color_c0c4cc':'hold_text_color_c0c4cc'"
                      @click="onClearSelectedKeywordClick">
                      <i class="el-icon-delete"></i>
                      清空
                    </span>
                  </div>
                  <div class="h5"></div>
                  <div class="keywords_content_wrapper border_wrapper flex_1">
                    <div v-for="(results,p_index) in keywordsResult.results" :key="p_index" style="margin: 10px">
                      <div class="keywords_content_main flex_row " style="background: #f5f5f5">
                        <span class="hold_text_color_c0c4cc description_text_size_12"
                              style="line-height: 24px;margin-left: 10px">{{p_index + 1}}
                        </span>
                        <div v-for="(item,index) in results['text_array']" :key="index" class="main_word">
                          <el-button :plain="results['keywords_index_array'][index]!==1"
                                     :type="results['keywords_index_array'][index]===1?'primary':''"
                                     size="small"
                                     @click="onKeywordSingleClick(index,p_index,$event)">
                            {{item}}
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-card>
            </template>
            <template v-else>
              <!-- 加载动画 -->
              <div class="h20"></div>
              <el-skeleton :rows="6" animated/>
            </template>
          </div>
        </el-main>
      </el-container>
    </div>
    <!--底部-->
    <div v-if="isShowInputTipView" class="flex_row">
      <div class="w30"></div>
      <div class="w30"></div>
      <div class="flex_col">
        <div class="flex_1"></div>
        <div class="flex_row align_center">
          <span style="font-size: 12px;font-weight: bold;color: #999">作者微信号 : heiscuiwang</span>
          <span class="w10"></span>
          <el-link style="font-size: 12px" type="success"
                   @click="onLinkClicked('https://datasmark.oneoneone.cn/images/qrcode.jpeg')"
          >#点击进入微信群
          </el-link>
        </div>
      </div>
      <div class="flex_1"></div>
      <div class="flex_row align_center">
        <div class="flex_col_allcenter">
          <el-image
            :src="require('/static/images/xkj_android_qrcode.png')"
            style="width: 100px; height: 100px"
          ></el-image>
          <el-link type="info"
                   @click="onLinkClicked('https://www.pgyer.com/xiaokj')"
          >Android v105
          </el-link>
        </div>
        <div class="w30"></div>
        <div class="flex_col_allcenter">
          <el-image
            :src="require('/static/images/xkj_ios_qrcode.png')"
            style="width: 100px; height: 100px"
          ></el-image>
          <el-link type="info"
                   @click="onLinkClicked('https://apps.apple.com/cn/app/%E5%B0%8F%E5%8F%A3%E8%AF%80/id6448202230')"
          >iOS v105
          </el-link>
        </div>
      </div>
      <div class="w30"></div>
      <div class="w30"></div>
    </div>
    <!-- 版权 -->
    <span class="not_main_text_color_909399 description_text_size_12"> In-House v1.0.6 Beta</span>
    <!--  结果弹框-->
    <ResultDialog
      ref="ResultDialog"
      :dialog-visible="isShowResultDialog"
      :generate-new-words="generateNewWords"
      :keywords-result="keywordsResult"
      :predict-text="predictText"
      @onCloseClick="isShowResultDialog=false"
      @onGenerateNewSentenceClick="onGenerateNewSentenceClick"
    ></ResultDialog>
  </div>
</template>
<script>
import ResultDialog from '@/components/ResultDialog'
import {Cons} from '@/libs/constant'
import http from '@/libs/http'
import {re_removeAllSpace} from '@/libs/utils'
import axios from 'axios'
import _ from 'lodash'

export default {
  name: 'Main',
  components: {
    ResultDialog
  },
  data() {
    return {
      isLoading: false,
      http_source: null, //存放取消的请求方法
      separeteContentTags: Cons.separeteContentTags, // 自动分割规则列表
      separetedUserInputDataArray: [],
      isShowInputTipView: true,
      isGenerateNewWords: false, //是否在请求生成新词,比较耗时
      isGenerateNewSentance: false, //是否在请求生成新句,比较耗时
      isShowResultDialog: false,
      generateNewWords: {}, // 从关键词中生成的新词
      finalWords: [],
      userInputData: '',  // 原始输入数据
      keywordsResult: {
        results: []
      },
      predictText: [],
      chartGptKeys: []
    }
  },
  computed: {
    finalSelectedKeyWordsList() {
      return this.keywordsResult.results.reduce((pre, cur) => {
        return pre.concat({'keywords': cur['keywords_array']})
      }, [])
    },
    isDisabled() {
      return !this.userInputData || this.isLoading || this.isGenerateNewWords || this.isGenerateNewSentance
    }
  },
  mounted() {
    console.log('mounted')
    this.initEvent()
    this.http_getChartGptKey()
  },
  destroyed() {
    console.log('destroyed')
  },
  methods: {
    handleScroll() {
      console.log('handleScroll')
    },
    initEvent() {
      document.onkeydown = (e) => {
        //事件对象兼容
        let evn = e || event || window.event
        let key = evn.keyCode || evn.which || evn.charCode
        // 回车键
        if (key === 13) {
          //this.userEnterKeyUp(e);
        }
      }
    }, // 响应用户回车事件
    userEnterKeyUp() {
    },
    // 生成列表数据
    // ›››››››››››››››››››››››››››› 网络请求 ‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹
    http_cancel() {
      if (typeof this.http_source === 'function') {
        this.http_source('终止请求') //取消请求
        this.isLoading = false
      }
    },
    http_getChartGptKey() {
      this.isLoading = true
      http.get('/hello')
          .then(res => {
            this.chartGptKeys = res.data
            console.log(this.chartGptKeys)
          })
    },
    http_getKeywords() {
      this.isLoading = true
      http.post('/getKeywords', {
            texts: this.separetedUserInputDataArray
          }, {
            cancelToken: new axios.CancelToken((c) => {
              this.http_source = c
            })
          })
          .then(res => {
            this.keywordsResult = res.data
            this.isLoading = false
          })
          .catch(err => {
            this.isLoading = false
            if (this.axios.isCancel(err)) {
              console.log('Rquest canceled', err.message) //请求如果被取消，这里是返回取消的message
            }
            else {
              //handle error
              console.log(err)
              this.$message.error(err.toLocaleString())
            }
          })
          .finally(() => {
            this.http_source = null
            //this.$nextTick(() => this.$refs.mark.focus());
          })
    },
    http_getPredict() {
      this.isLoading = true
      this.isGenerateNewWords = true
      http.post('/generateNewWords', {
            keywords: this.finalSelectedKeyWordsList,
            blur: true
          })
          .then(async res => {
            this.isLoading = false
            this.generateNewWords = res.data
            this.finalWords = []
            this.generateNewWords['new_words_result'].forEach(item => {
              this.finalWords.push(item[0])
            })
            this.http_getPredictText()
            // this.doGetPredictTextByChatgpt()
            this.isGenerateNewWords = false
          })
          .catch(err => {
            this.isGenerateNewWords = false
            this.isLoading = false
            console.log(err)
            this.$message.error(err.toLocaleString())
          })
    },
    http_getPredictText() {
      // 生成语句
      this.isGenerateNewSentance = true
      this.predictText = []
      http.post('/getPredictText', {
            keywords: this.finalWords
          })
          .then(res => {
            this.predictText = res.data['predict']
            console.log(this.predictText)
            this.isGenerateNewSentance = false
            this.isShowResultDialog = true
            this.$refs.ResultDialog.drawPipline()
          })
          .catch(err => {
            this.isGenerateNewSentance = false
            console.log(err)
            this.$message.error(err.toLocaleString())
          })
    },
    doGetPredictTextByChatgpt() {
      if (this.chartGptKeys.length === 0) {
        this.http_getPredictText()
        return
      }
      const _Authorization = `Bearer ${this.chartGptKeys[0]['key']}`
      this.isGenerateNewSentance = true
      const url = 'https://service-ci2ekthk-1308104785.hk.apigw.tencentcs.com/v1/chat/completions'
      http.post(url, {
            model: 'gpt-3.5-turbo',
            temperature: 0.7,
            messages: [
              {
                role: 'user',
                content: `请使用引号中给定的词"${this.finalWords.join(
                  ' '
                )}"，按顺序生成一句短语,生成的内容要生动、夸张、搞笑,长度${this.finalWords.length * 4}左右,给定的词用引号包围。`
              }
            ],
            n: 8
          }, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: _Authorization
            }
          })
          .then((res) => {
            // res.data
            this.predictText = res.data['choices'].map((item) => {
              return [
                '',
                item['message']['content'].replace('\n', '')
              ]
            })
            this.isGenerateNewSentance = false
            this.isShowResultDialog = true
            this.$refs.ResultDialog.drawPipline()
          })
          .catch((err) => {
            console.log(err)
            // 提示网络异常
            this.$message.info('"网络异常,切换模型"')
            this.http_getPredictText()
          })
    },
    // ›››››››››››››››››››››››››››› 组件事件 ‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹
    onInputTipViewClick() {
      this.isShowInputTipView = false
      this.$nextTick(() => {
        this.$refs['mark'].focus()
      })
    },
    onClearUserInputClick() {
      this.userInputData = ''
      this.separetedUserInputDataArray = []
      // 清理关键词
      this.$set(this.keywordsResult, 'results', [])
    },
    onUserInputDataChange: _.debounce(function(value) {
      // 用户手动分割
      this.isUserManualSeparation = /[\r\n]/.exec(value)
      if (value) {
        this.formatUserInputData(value)
            .then(data => {
              // 用户手动分割
              if (this.isUserManualSeparation) {
                this.separetedUserInputDataArray = data
              }
              // 自动分割
              else {
                this.separetedUserInputDataArray = this.extractArrayFromData(data)
              }
              // 自动请求获取关键词
              if (this.isLoading) {
                this.http_cancel()
              }
              this.http_getKeywords()
              // 再次切分复句
              //this.separetedUserDataArray = this.separetedUserDataArray.map(_data => {
              //  return {
              //    text: _data,
              //    children: this.extractArrayFromData(_data),
              //  };
              //});
            })
      }
      else {
        this.onClearUserInputClick()
      }
    }, 500),
    onFisrtStepFinishClick() {
      this.$emit('onFisrtStepFinishClick', this.separetedUserInputDataArray)
    },
    onModifyUserInputDataClick() {
      this.$emit('onModifyUserInputDataClick')
    },
    // 点击移除关键词
    onKeywordRemoveClick(index, p_index = undefined) {
      const _keyword = this.keywordsResult.results[p_index]['keywords_array'][index]
      this.$set(this.keywordsResult.results[p_index], 'keywords_index_array', _.fill(this.keywordsResult.results[p_index]['keywords_index_array'], 0, _keyword['start'], _keyword['end']))
      this.keywordsResult.results[p_index]['keywords_array'].splice(index, 1)
    },
    // 点击清空所有关键词
    onClearSelectedKeywordClick() {
      for (const _index in this.keywordsResult.results) {
        this.$set(this.keywordsResult.results[_index], 'keywords_array', [])
        this.$set(this.keywordsResult.results[_index], 'keywords_index_array', _.fill(this.keywordsResult.results[_index]['keywords_index_array'], 0))
      }
    },
    // 选择关键词界面,点击选词
    onKeywordSingleClick(index, p_index = undefined, ev) {
      // 使按钮失去焦点
      if (ev.target.nodeName === 'SPAN' || ev.target.nodeName === 'I') {
        ev.target.parentNode.blur()
      }
      else {
        ev.target.blur()
      }
      const _status = this.keywordsResult.results[p_index]['keywords_index_array'][index] === 1
      this.keywordsResult.results[p_index]['keywords_index_array'].splice(index, 1, _status ? 0 : 1)
      this.handleKeywordModify(index, _status, p_index)
    },
    // 选择关键字后的逻辑操作
    handleKeywordModify(index, status, p_index = undefined) {
      // 分段风格
      // 如果点击的是实
      if (status) {
        for (const _index in this.keywordsResult.results[p_index]['keywords_array']) {
          const keyword_index = parseInt(_index)
          const keyword = this.keywordsResult.results[p_index]['keywords_array'][keyword_index]
          // 如果当前位置本身只有一个字,就直接删除
          if (keyword['keyword'].length === 1 && index === keyword['start']) {
            this.keywordsResult.results[p_index]['keywords_array'].splice(keyword_index, 1)
            return
          }
          // 左边缘
          if (index === keyword['start']) {
            const _keyword = keyword
            //_keyword['keyword'] = _keyword['keyword'].slice(0,-1)
            _keyword['keyword'] = _keyword['keyword'].slice(1)
            _keyword['start'] = _keyword['start'] + 1
            _keyword['len'] = _keyword['len'] - 1
            this.keywordsResult.results[p_index]['keywords_array'].splice(keyword_index, 1, _keyword)
            break
          }
          // 右边缘,需要减一
          else if (index === keyword['end'] - 1) {
            const _keyword = keyword
            _keyword['keyword'] = _keyword['keyword'].slice(0, -1)
            _keyword['end'] = _keyword['end'] - 1
            _keyword['len'] = _keyword['len'] - 1
            this.keywordsResult.results[p_index]['keywords_array'].splice(keyword_index, 1, _keyword)
            break
          }
          // 处于中间,需要切分成两个
          else if (index > keyword['start'] && index < keyword['end']) {
            const _keyword1 = JSON.parse(JSON.stringify(keyword))
            _keyword1['keyword'] = _keyword1['keyword'].slice(0, index - _keyword1['start'])
            _keyword1['end'] = index
            _keyword1['len'] = _keyword1['end'] - _keyword1['start']
            const _keyword2 = JSON.parse(JSON.stringify(keyword))
            _keyword2['keyword'] = _keyword2['keyword'].slice(_keyword1['len'] + 1)
            _keyword2['start'] = index + 1
            _keyword2['len'] = _keyword2['end'] - _keyword2['start']
            this.keywordsResult.results[p_index]['keywords_array'].splice(keyword_index, 1, _keyword1, _keyword2)
            break
          }
        }
      }
      // 否则点击的是空
      else {
        let _isMerged = false
        for (const _index in this.keywordsResult.results[p_index]['keywords_array']) {
          const keyword_index = parseInt(_index)
          const keyword = this.keywordsResult.results[p_index]['keywords_array'][keyword_index]
          // 需要合并到开头
          if (index + 1 === keyword['start']) {
            const _keyword = keyword
            //_keyword['keyword'] = _keyword['keyword'].slice(0,-1)
            _keyword['keyword'] = this.keywordsResult.results[p_index]['text_array'][index] + _keyword['keyword']
            _keyword['start'] = _keyword['start'] - 1
            _keyword['len'] = _keyword['len'] + 1
            this.keywordsResult.results[p_index]['keywords_array'].splice(keyword_index, 1, _keyword)
            _isMerged = true
            break
          }
          // 需要合并到结尾
          else if (index - 1 === keyword['start'] || index + 1 === keyword['end'] - 1 || index - 1 ===
            keyword['end'] - 1) {
            const _keyword = keyword
            // 当插入的字可以连接前后
            if (this.keywordsResult.results[p_index]['keywords_array'][keyword_index + 1] && index ===
              _keyword['end'] &&
              index ===
              this.keywordsResult.results[p_index]['keywords_array'][keyword_index + 1]['start'] - 1) {
              _keyword['keyword'] = _keyword['keyword'] + this.keywordsResult.results[p_index]['text_array'][index] +
                this.keywordsResult.results[p_index]['keywords_array'][keyword_index + 1]['keyword']
              _keyword['end'] = _keyword['end'] + 1 +
                this.keywordsResult.results[p_index]['keywords_array'][keyword_index + 1]['len']
              _keyword['len'] = _keyword['len'] + 1 +
                this.keywordsResult.results[p_index]['keywords_array'][keyword_index + 1]['len']
              this.keywordsResult.results[p_index]['keywords_array'].splice(keyword_index, 2, _keyword)
            }
            else {
              _keyword['keyword'] = _keyword['keyword'] + this.keywordsResult.results[p_index]['text_array'][index]
              _keyword['end'] = _keyword['end'] + 1
              _keyword['len'] = _keyword['len'] + 1
              this.keywordsResult.results[p_index]['keywords_array'].splice(keyword_index, 1, _keyword)
            }
            _isMerged = true
            break
          }
        }
        // 循环后未能合并插入,就单独成为一项
        if (!_isMerged) {
          // 判断需要插入的位置
          let _correct_index = 0
          const _keyword = {
            'keyword': this.keywordsResult.results[p_index]['text_array'][index],
            'start': index,
            'end': index + 1,
            'len': 1
          }
          for (const keyword_index in this.keywordsResult.results[p_index]['keywords_array']) {
            const keyword = this.keywordsResult.results[p_index]['keywords_array'][keyword_index]
            // 如果待插入的索引比另外一个的开始小,就插入到它前面
            if (keyword['start'] > index) {
              _correct_index = keyword_index
              break
            }
          }
          // 判断是否找到位置,否则可能是处于末尾.
          if (_correct_index) {
            this.keywordsResult.results[p_index]['keywords_array'].splice(_correct_index, 0, _keyword)
          }
          else {
            this.keywordsResult.results[p_index]['keywords_array'].push(_keyword)
          }
        }
      }
    },
    // ›››››››››››››››››››››››››››› 文本处理 ‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹
    // 格式化用户输入(回车分割、去掉空白)
    formatUserInputData(data) {
      if (this.isUserManualSeparation) {
        return new Promise((resolve, reject) => {
          try {
            // 用户输入回车手动分割,正则切割成数组后清理数据
            data = data.split(/[\n,]/g)
                       .filter(value => {
                         return !!value
                       })
                       .map(item => {
                         item = re_removeAllSpace(item)
                         //item = re_punctuationC2E(item);
                         return item
                       })
            resolve(data)
          }
          catch (e) {
            reject(e)
          }
        })
      }
      else {
        return new Promise((resolve, reject) => {
          try {
            data = re_removeAllSpace(data)
            //data = re_punctuationC2E(data);
            resolve(data)
          }
          catch (e) {
            reject(e)
          }
        })
      }
    },
    // 将格式化后的数据抽取成数组
    extractArrayFromData(data) {
      // 倒序查找
      const _separeteContentTags = JSON.parse(JSON.stringify(this.separeteContentTags))
                                       .reverse()
      // 遍历分离规则
      return this.extracteDataByTags(_separeteContentTags, data)
    },
    // 通过规则切分字符串
    extracteDataByTags(separeteContentTags, data) {
      let result = []
      // 1.选取开头前三个字符进行匹配,选出合适的规则
      let matchedReg = ''
      for (let i = 1; i < data.length; i++) {
        const matchStr = data.substring(0, i)
        for (const separeteContentTag of separeteContentTags) {
          const _reg = new RegExp(separeteContentTag.tag[0], 'g')
          if (_reg.test(matchStr)) {
            matchedReg = _reg
            break
          }
        }
        // 如果检测到合适的匹配就用结束
        if (matchedReg) {
          break
        }
      }
      // 2.分割字符串
      if (matchedReg) {
        result = data.split(matchedReg)
                     .filter(value => {
                       return !!value
                     })
      }
      // 3.如果无法自动分离(规则全部无效),就获取原始数据
      if (result.length === 0) {
        // 按原内容输出
        result.push(data)
      }
      return result
    },
    onGetResultButtonClick() {
      this.http_getPredict()
    },
    onGenerateNewSentenceClick(finalWords) {
      this.isShowResultDialog = false
      this.finalWords = finalWords
      this.http_getPredictText()
      // this.doGetPredictTextByChatgpt()
    },
    onLinkClicked(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
<style lang="scss">
@import "../assets/globals";

.el-dialog {
  border-radius: 15px !important;
}

.el-steps--simple {
  background: transparent !important;
}

.el-textarea__inner {
  border: 1px dashed #DCDFE6 !important;
}

.el-button {
  border: 1px solid #dcdfe659;
}

.el-button--small, .el-button--small.is-round {
  padding: 5px !important;
}

.ant-empty-description {
  color: #c0c4cc;
  font-size: 12px;
}

.tip_container_padding {
  padding: 50px 100px;
  box-sizing: border-box;
}

.container {
  background: #2980B9; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #FFFFFF, #6DD5FA, #2980B9); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #FFFFFF, #6DD5FA, #2980B9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.seperate-resault-view {
  border: 1px dashed #DCDFE6 !important;
  height: 264px;
  overflow: auto;
}

//keywords
.keywords_view_header_card {
  .el-card__body {
    padding: 0 20px;
  }
}

.border_wrapper {
  border: 1px dashed #DCDFE6;
}

.keywords_content_wrapper {
  overflow: auto;

  .keywords_content_main {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    overflow: auto;

    box-sizing: border-box;
    padding-top: 10px;

    .main_word {
      margin-left: 10px;
      margin-bottom: 10px
    }
  }
}

.new_words_header_card {
  .el-card__header {
    padding: 10px;
  }
}
</style>
