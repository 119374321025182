<template>
  <!-- 2.提取关键词 -->
  <div v-show="currentStep === 1" class="animate__animated animate__fadeIn keywords_view flex_col height_100">
    <!-- 头部已录文本预览及修改  -->
    <el-card class="keywords_view_header_card flex_col" shadow="never">
      <div class="header_keywords_original_data width_100 flex_row_allcenter" style="height: 70px">
        <span class="w5"></span>
        <span class="small_normal_text_size_13 hold_text_color_c0c4cc">原内容 :</span>
        <span class="w5"></span>
        <div class="header_orginal_data flex_1 flex_row align_center height_100 normal_text_size_14 not_main_text_color_909399  text_align_left padding_10_0 overflow_auto"
        >
          {{ userInputData }}
        </div>
        <el-divider direction="vertical"></el-divider>
        <el-button :disabled="isLoading"
                   size="mini"
                   plain
                   round
                   @click="onModifyUserInputDataClick">修 改
        </el-button>
      </div>
    </el-card>
    <div class="h5"></div>
    <!-- 自动提取关键词 -->
    <el-card v-loading="isLoading"
             shadow="never"
             class="height_100 flex_col"
             :body-style="{padding:'25px',flex:'1',overflow:'auto'}">
      <!--内容布局-->
      <div class="keywords_content flex_col height_100">
        <!--关键词展示-->
        <div slot="header" class="flex_row_allcenter keywords_content_wrapper border_wrapper padding_10">
          <span class="w5"></span>
          <span class="small_normal_text_size_13 hold_text_color_c0c4cc">关键词 :</span>
          <span class="w5"></span>
          <!--关键词列表-->
          <div class="flex_1 flex_row overflow_auto flex_warp ">
            <div v-for="(result,p_index) in keywordsResult['results']" :key="p_index" class="flex_row">
              <div v-for="(item,index) in result['keywords_array']"
                   :key="item.keyword" style="margin-top: 5px">
                <el-tag
                    type="primary"
                    closable
                    @close="onKeywordRemoveClick(index,p_index)"
                    style="margin-left: 5px"
                    effect="dark">
                  {{ item.keyword }}
                </el-tag>
              </div>
              <div class="w5"></div>
            </div>
          </div>
          <span class="w10"></span>
          <span style="color: #D7D7D7" @click="onClearSelectedKeywordClick">
            <i class="el-icon-delete"></i>
            清空
          </span>
        </div>
        <div class="h5"></div>
        <div class="keywords_content_wrapper border_wrapper flex_1">
          <div v-for="(results,p_index) in keywordsResult.results" :key="p_index" style="margin: 10px">
            <div class="keywords_content_main flex_row " style="background: #f5f5f5">
              <span class="hold_text_color_c0c4cc description_text_size_12"
                    style="line-height: 24px;margin-left: 10px">{{ p_index + 1 }}
              </span>
              <div v-for="(item,index) in results['text_array']" :key="index" class="main_word">
                <el-button @click="onKeywordSingleClick(index,p_index,$event)"
                           size="small"
                           :type="results['keywords_index_array'][index]===1?'primary':''"
                           :plain="results['keywords_index_array'][index]!==1">
                  {{ item }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="keywords_content_footer width_100" style="margin-top: 20px;">
          <el-button
                     type="primary"
                     round
                     style="width: 150px;"
                     @click="onSecondStepFinishClick"
          >生成助记句
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import http from '@/libs/http';
import _ from 'lodash';
export default {
  name: 'ExtractKeywords',
  props:{
    currentStep:{
      type:Number,
      required:true,
      default:-1
    },
    userInputData:{
      type:String,
      required:true,
      default:''
    },
    separetedUserInputDataArray:{
      type:Array,
      required:true,
      default:[]
    },

  },
  watch: {
    currentStep (val) {
      if (val === 1){
        this.http_getKeywords()
      }
    }
  },
  data() {
    return {
      isLoading: false,
      keywordsResult: {
        results: [],
      },
    };
  },
  mounted() {
    // 从fastapi中拿到关键词数组


  },
  methods: {

    http_getKeywords(){
      this.isLoading = true;
      http.post('/getKeywords', {
        texts: this.separetedUserInputDataArray,
      }).then(res => {
        this.keywordsResult = res.data;
        this.isLoading = false;
      }).catch(err => {
        this.isLoading = false;
        console.log(err);
      });
    },

    onModifyUserInputDataClick() {
      this.$emit('onModifyUserInputDataClick')
    },
    // 点击移除关键词
    onKeywordRemoveClick(index, p_index = undefined) {
      const _keyword = this.keywordsResult.results[p_index]['keywords_array'][index];
      this.$set(this.keywordsResult.results[p_index], 'keywords_index_array', _.fill(this.keywordsResult.results[p_index]['keywords_index_array'], 0, _keyword['start'], _keyword['end']));
      this.keywordsResult.results[p_index]['keywords_array'].splice(index, 1);
    },
    // 点击清空所有关键词
    onClearSelectedKeywordClick() {
      for (const _index in this.keywordsResult.results) {
        this.$set(this.keywordsResult.results[_index], 'keywords_array', []);
        this.$set(this.keywordsResult.results[_index], 'keywords_index_array', _.fill(this.keywordsResult.results[_index]['keywords_index_array'], 0));
      }
    },
    // 选择关键词界面,点击选词
    onKeywordSingleClick(index , p_index = undefined,ev) {
      // 使按钮失去焦点
      if (ev.target.nodeName === 'SPAN' || ev.target.nodeName === 'I') {
        ev.target.parentNode.blur();
      } else {
        ev.target.blur();
      }
      const _status = this.keywordsResult.results[p_index]['keywords_index_array'][index] === 1;
      this.keywordsResult.results[p_index]['keywords_index_array'].splice(index, 1, _status ? 0 : 1);
      this.handleKeywordModify(index, _status, p_index);
    },
    // 选择关键字后的逻辑操作
    handleKeywordModify(index, status, p_index = undefined) {
      // 分段风格
      // 如果点击的是实
      if (status) {
        for (const _index in this.keywordsResult.results[p_index]['keywords_array']) {
          const keyword_index = parseInt(_index);
          const keyword = this.keywordsResult.results[p_index]['keywords_array'][keyword_index];
          // 如果当前位置本身只有一个字,就直接删除
          if (keyword['keyword'].length === 1 && index === keyword['start']) {
            this.keywordsResult.results[p_index]['keywords_array'].splice(keyword_index, 1);
            return;
          }
          // 左边缘
          if (index === keyword['start']) {
            const _keyword = keyword;
            //_keyword['keyword'] = _keyword['keyword'].slice(0,-1)
            _keyword['keyword'] = _keyword['keyword'].slice(1);
            _keyword['start'] = _keyword['start'] + 1;
            _keyword['len'] = _keyword['len'] - 1;
            this.keywordsResult.results[p_index]['keywords_array'].splice(keyword_index, 1, _keyword);
            break;
          }
          // 右边缘,需要减一
          else if (index === keyword['end'] - 1) {
            const _keyword = keyword;
            _keyword['keyword'] = _keyword['keyword'].slice(0, -1);
            _keyword['end'] = _keyword['end'] - 1;
            _keyword['len'] = _keyword['len'] - 1;
            this.keywordsResult.results[p_index]['keywords_array'].splice(keyword_index, 1, _keyword);
            break;
          }
          // 处于中间,需要切分成两个
          else if (index > keyword['start'] && index < keyword['end']) {
            const _keyword1 = JSON.parse(JSON.stringify(keyword));
            _keyword1['keyword'] = _keyword1['keyword'].slice(0, index - _keyword1['start']);
            _keyword1['end'] = index;
            _keyword1['len'] = _keyword1['end'] - _keyword1['start'];
            const _keyword2 = JSON.parse(JSON.stringify(keyword));
            _keyword2['keyword'] = _keyword2['keyword'].slice(_keyword1['len'] + 1);
            _keyword2['start'] = index + 1;
            _keyword2['len'] = _keyword2['end'] - _keyword2['start'];
            this.keywordsResult.results[p_index]['keywords_array'].splice(keyword_index, 1, _keyword1, _keyword2);
            break;
          }
        }
      }
      // 否则点击的是空
      else {
        let _isMerged = false;
        for (const _index in this.keywordsResult.results[p_index]['keywords_array']) {
          const keyword_index = parseInt(_index);
          const keyword = this.keywordsResult.results[p_index]['keywords_array'][keyword_index];
          // 需要合并到开头
          if (index + 1 === keyword['start']) {
            const _keyword = keyword;
            //_keyword['keyword'] = _keyword['keyword'].slice(0,-1)
            _keyword['keyword'] = this.keywordsResult.results[p_index]['text_array'][index] + _keyword['keyword'];
            _keyword['start'] = _keyword['start'] - 1;
            _keyword['len'] = _keyword['len'] + 1;
            this.keywordsResult.results[p_index]['keywords_array'].splice(keyword_index, 1, _keyword);
            _isMerged = true;
            break;
          }
          // 需要合并到结尾
          else if (index - 1 === keyword['start'] || index + 1 === keyword['end'] - 1 || index - 1 ===
              keyword['end'] - 1) {
            const _keyword = keyword;
            // 当插入的字可以连接前后
            if (this.keywordsResult.results[p_index]['keywords_array'][keyword_index + 1] && index === _keyword['end'] &&
                index ===
                this.keywordsResult.results[p_index]['keywords_array'][keyword_index + 1]['start'] - 1) {
              _keyword['keyword'] = _keyword['keyword'] + this.keywordsResult.results[p_index]['text_array'][index] +
                  this.keywordsResult.results[p_index]['keywords_array'][keyword_index + 1]['keyword'];
              _keyword['end'] = _keyword['end'] + 1 +
                  this.keywordsResult.results[p_index]['keywords_array'][keyword_index + 1]['len'];
              _keyword['len'] = _keyword['len'] + 1 +
                  this.keywordsResult.results[p_index]['keywords_array'][keyword_index + 1]['len'];
              this.keywordsResult.results[p_index]['keywords_array'].splice(keyword_index, 2, _keyword);
            } else {
              _keyword['keyword'] = _keyword['keyword'] + this.keywordsResult.results[p_index]['text_array'][index];
              _keyword['end'] = _keyword['end'] + 1;
              _keyword['len'] = _keyword['len'] + 1;
              this.keywordsResult.results[p_index]['keywords_array'].splice(keyword_index, 1, _keyword);
            }
            _isMerged = true;
            break;
          }
        }
        // 循环后未能合并插入,就单独成为一项
        if (!_isMerged) {
          // 判断需要插入的位置
          let _correct_index = 0;
          const _keyword = {
            'keyword': this.keywordsResult.results[p_index]['text_array'][index],
            'start': index,
            'end': index + 1,
            'len': 1,
          };
          for (const keyword_index in this.keywordsResult.results[p_index]['keywords_array']) {
            const keyword = this.keywordsResult.results[p_index]['keywords_array'][keyword_index];
            // 如果待插入的索引比另外一个的开始小,就插入到它前面
            if (keyword['start'] > index) {
              _correct_index = keyword_index;
              break;
            }
          }
          // 判断是否找到位置,否则可能是处于末尾.
          if (_correct_index) {
            this.keywordsResult.results[p_index]['keywords_array'].splice(_correct_index, 0, _keyword);
          } else {
            this.keywordsResult.results[p_index]['keywords_array'].push(_keyword);
          }
        }
      }
    },

    onSecondStepFinishClick() {
      this.$emit('onSecondStepFinishClick',this.keywordsResult)
    }
  }
};
</script>
<style scoped>
</style>
