/**
 * 正则 - 移除所有空格
 */
export function re_removeAllSpace(text) {
  //去掉回车 去掉空格
  return text.replace(/[\r\n]/g, '').replace(/\s/ig, '');
}

/**
 * 正则 - 中文标点转英文标点
 * 顿号不转
 */
export function re_punctuationC2E(text) {
  const ChinesePunction = ['！', '“', '”', '；', '〈', '〉', '《', '》', '〈', '〉', '。', '、', '，', '［', '］', '〖', '〗', '【', '】', '〔', '〕', '（', '）', '？', '：', '１', '２', '３', '４', '５', '６', '７', '８', '９'];
  const EnglishPunction = ['!', '"', '"', ';', '<', '>', '<', '>', '<', '>', '.', '、', ',', '[', ']', '[', ']', '[', ']', '[', ']', '(', ')', '?', ':', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  ChinesePunction.forEach(function(ele, index) {
    text = text.replace(new RegExp(ele, 'g'), EnglishPunction[index]);
  });
  return text;
}