<template>
  <!--1.录入文本-->
  <el-card v-show="currentStep === 0" class="flex_col height_100" :body-style="{padding:'25px',flex:'1'}">
    <!--显示输入提示view-->
    <div v-show="isShowInputTipView"
         type="dashed"
         class="flex_col_allcenter height_100"
         style="border: 1px dashed #DCDFE6"
         @click="onInputTipViewClick">
      <div class="flex_col_allcenter">
        <span class="main_text_size_20 normal_text_color_606266"
              style="font-size: 24px;font-weight: bold">自动提取关键词，自动生成助记语句。
        </span>
        <div class="h20"></div>
        <span class="small_title_text_size_16 not_main_text_color_909399">automatic extraction, automatic generation.</span>
        <div class="h20"></div>
        <div class="h20"></div>
        <el-button class="animate__animated animate__shakeX"
                   type="primary"
                   icon="el-icon-edit">开始录入文本
        </el-button>
      </div>
    </div>
    <!--用户原始数据输入框-->
    <div v-show="!isShowInputTipView" class="flex_col height_100 animate__animated animate__fadeIn">
      <div class="user_input_view flex_col_allcenter flex_1">
        <span class="user_input_view_title title_text_size_18 main_text_color_303133"
              style="text-align: center;margin-bottom: 10px">录入文本
        </span>
        <div class="user_input_view_description flex_row_allcenter"
             style="width: 100%;padding: 0 50px;box-sizing: border-box;margin-bottom: 20px">
          <span class="description_text_size_12 hold_text_color_c0c4cc">文本内容切分成小片段，将极大提高关键词提取效果。</span>
        </div>
        <div class="user_input_view_main flex_row flex_1"
             style="width: 100%;padding: 0 50px;box-sizing: border-box;">
          <!--用户输入-->
          <div class="flex_col flex_1">
            <div class="flex_row_allcenter" style="height: 44px;width: 100%;background: #F9F9F9">
              <span class="w10"></span>
              <span style="color: #D7D7D7" @click="onClearUserInputClick">
                <i class="el-icon-delete"></i>
                清空
              </span>
              <span class="flex_1"></span>
              <span class="hidden-sm-only normal_text_color_606266"
                    v-if="separetedUserInputDataArray.length>0"
              >自动切分成 <span style="color: #E6A23C">{{ this.separetedUserInputDataArray.length }}</span> 段
              </span>
              <el-divider v-if="separetedUserInputDataArray.length>0" direction="vertical"></el-divider>
              <span class="w5"></span>
              <a-button type="dashed" @click="onPreviewSegmentClick">
                {{ isShowPreviewView ? '隐藏' : '预览' }}分段
                <i class="el-icon-d-arrow-right"></i>
              </a-button>
              <span class="w10"></span>
            </div>
            <el-input
                type="textarea"
                ref="mark"
                :rows="12"
                maxlength="500"
                show-word-limit
                resize="none"
                :value="value"
                @input="onUserInputDataChange"
                placeholder="请输入需要记忆的内容。通过回车换行或默认规则，分割成小片段，提升抽取效果。"
                >
            </el-input>
          </div>
          <!--分割间距-->
          <div v-if="isShowPreviewView" class="w20"/>
          <!-- 分段结果 -->
          <div v-if="isShowPreviewView" class="flex_col flex_1">
            <div class="flex_row align_center" style="height: 44px;width: 100%;background: #F9F9F9">
              <span class="w10"></span>
              <span class="flex_1" style="color: #D7D7D7">分段结果</span>
              <span class="w10"></span>
            </div>
            <div class="seperate-resault-view">
              <div v-for="(content,index) in separetedUserInputDataArray"
                   :key="index"
                   class="flex_row small_normal_text_size_13"
                   style="border-radius: 4px;padding: 4px;background: #F2F5F7;margin: 4px 4px 0 4px">
                <div class="flex_1 text_one_line text_align_left" style="width: 0;">
                  <el-tooltip :content="content" placement="bottom" effect="light">
                    <span class="width_100 normal_text_color_606266">{{ `${index + 1}. ${content}` }}</span>
                  </el-tooltip>
                </div>
              </div>
              <div v-if="separetedUserInputDataArray.length === 0"
                   class="width_100 height_100 flex_col_allcenter">
                <a-empty description="按规则(默认回车换行)自动切分片段"></a-empty>
              </div>
            </div>
          </div>
        </div>
        <div class="user_input_view_footer width_100" style="margin-bottom: 20px">
          <el-button :disabled="separetedUserInputDataArray.length===0"
                     type="primary"
                     round
                     style="width: 150px;"
                     @click="onFisrtStepFinishClick"
          >提取关键词
          </el-button>
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>
import {re_removeAllSpace} from '@/libs/utils';
import {Cons}              from '@/libs/constant';

export default {
  name: 'UserInputContent',
  props:{
    value:String,
    currentStep:{
      type:Number,
      required:true,
      default:-1
    }
  },
  data() {
    return {
      separeteContentTags: Cons.separeteContentTags, // 自动分割规则列表
      separetedUserInputDataArray:[],
      isShowInputTipView: true,
      isShowPreviewView:false,
      isUserManualSeparation: false,// 是否手动，通过判断是否有回车换行来标识是否手动分割
    };
  },
  methods: {

    onInputTipViewClick() {
      this.isShowInputTipView = false;
      this.$nextTick(() => {
        this.$refs['mark'].focus();
      });
    },
    onPreviewSegmentClick() {
      this.isShowPreviewView = !this.isShowPreviewView;
      this.$nextTick(() => {
        this.$refs['mark'].focus();
      });
    },
    onClearUserInputClick() {
      this.separetedUserInputDataArray = []
    },
    onUserInputDataChange(value) {
      this.$emit("input", value);
      this.isUserManualSeparation = /[\r\n]/.exec(value);
      if (value) {
        this.formatUserInputData(value).then(data => {
          // 用户手动分割
          if (this.isUserManualSeparation) {
            this.separetedUserInputDataArray = data
          }
          // 自动分割
          else {
            this.separetedUserInputDataArray = this.extractArrayFromData(data)
          }
          // 再次切分复句
          //this.separetedUserDataArray = this.separetedUserDataArray.map(_data => {
          //  return {
          //    text: _data,
          //    children: this.extractArrayFromData(_data),
          //  };
          //});
        });
      } else {
        this.separetedUserInputDataArray = []
      }


    },
    // 格式化用户输入(回车分割、去掉空白)
    formatUserInputData(data) {
      if (this.isUserManualSeparation) {
        return new Promise((resolve, reject) => {
          try {
            // 用户输入回车手动分割,正则切割成数组后清理数据
            data = data.split(/[\n,]/g).filter(value => {
              return !!value;
            }).map(item => {
              item = re_removeAllSpace(item);
              //item = re_punctuationC2E(item);
              return item;
            });
            resolve(data);
          } catch (e) {
            reject(e);
          }
        });
      } else {
        return new Promise((resolve, reject) => {
          try {
            data = re_removeAllSpace(data);
            //data = re_punctuationC2E(data);
            resolve(data);
          } catch (e) {
            reject(e);
          }
        });
      }
    },
    // 将格式化后的数据抽取成数组
    extractArrayFromData(data) {
      // 倒序查找
      const _separeteContentTags = JSON.parse(JSON.stringify(this.separeteContentTags)).reverse();
      // 遍历分离规则
      return this.extracteDataByTags(_separeteContentTags, data);
    },
    // 通过规则切分字符串
    extracteDataByTags(separeteContentTags, data) {
      let result = [];
      // 1.选取开头前三个字符进行匹配,选出合适的规则
      let matchedReg = '';
      for (let i = 1; i < data.length; i++) {
        const matchStr = data.substring(0, i);
        for (const separeteContentTag of separeteContentTags) {
          const _reg = new RegExp(separeteContentTag.tag[0], 'g');
          if (_reg.test(matchStr)) {
            matchedReg = _reg;
            break;
          }
        }
        // 如果检测到合适的匹配就用结束
        if (matchedReg) break;
      }
      // 2.分割字符串
      if (matchedReg) {
        result = data.split(matchedReg).filter(value => {
          return !!value;
        });
      }
      // 3.如果无法自动分离(规则全部无效),就获取原始数据
      if (result.length === 0) {
        // 按原内容输出
        result.push(data);
      }
      return result;
    },

    onFisrtStepFinishClick() {
      this.$emit('onFisrtStepFinishClick',this.separetedUserInputDataArray)
    },

  },
};
</script>
<style scoped>
</style>