import axios from 'axios'//它是一个HTTP工具，用于与后端进行数据交互。
//生产环境
//测试环境
//开发环境

// const baseURL = "http://localhost:5555/api/v1"
let baseURL = "https://xkj-api.oneoneone.cn/api/v1"

// 判断当前环境
 if (process.env.NODE_ENV === 'development') {
   baseURL = "/api/v1"
 } else if (process.env.NODE_ENV === 'production') {
   baseURL = "https://xkj-api.oneoneone.cn/api/v1"
 } else if (process.env.NODE_ENV === 'test') {
   baseURL = "https://xkj-api.oneoneone.cn/api/v1"
 }

//要是跨域的话，就请求本地服务器跳转到vue.config.js解决跨域问题

//c创建一个axios实例
const http = axios.create({
   baseURL,
  timeout: 60000,
  headers: {}
})

//封装请求拦截器
http.interceptors.request.use((config)=> {
  //在请求被send出去之前，你可以在这里做一些事情
  //console.log('请求拦截器', config)
  //每次发送请求之前都要携带token鉴定身份去访问后端数据库
  return config
}, (error)=> {
  return Promise.reject(error)
})

//封装响应拦截器
http.interceptors.response.use((res) =>{
  //console.log('响应拦截器', res)
  return res
},  (error)=> {
  return Promise.reject(error)
})


export default http
