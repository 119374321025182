<template>
  <div v-show="currentStep === 2"
       class="animate__animated animate__fadeIn keywords_view flex_col height_100 text_align_left">
    <!--原文本内容-->
    <span class="hold_text_color_c0c4cc normal_text_size_14 margin_bottom_5">
      <i class="el-icon-s-order"></i>
      原文本内容
    </span>
    <el-card class="flex_col" shadow="never" :body-style="{padding:'10px'}">
      <div class="flex_row flex_warp border_wrapper flex_1 overflow_auto padding_10"
           style="max-height: 150px;padding-bottom: 0">
        <div class="flex_row"
             v-for="(results,p_index) in keywordsResult['results']"
             :key="p_index"
             style="height: 24px;margin-bottom: 10px">
          <div v-for="(item,index) in results['text_array']" :key="index">
            <el-button
                :id="`textword_${p_index}_${index}`"
                size="small"
                :style="{marginLeft:'1px',border:'none',padding:'2px !important'}"
                :type="results['keywords_index_array'][index]===1?'primary':''"
                plain>
              {{ item }}
            </el-button>
          </div>
        </div>
      </div>
    </el-card>
    <div class="h20"></div>
    <!-- 已选关键词 -->
    <span class="hold_text_color_c0c4cc normal_text_size_14 margin_bottom_5">
      <i class="el-icon-s-cooperation"></i>
      已选关键词
    </span>
    <el-card class=" flex_col" shadow="never" :body-style="{padding:'10px'}">
      <div class=" width_100 flex_row_allcenter border_wrapper padding_0_10">
        <div class=" flex_1 flex_row_allcenter height_100 small_normal_text_size_13 not_main_text_color_909399  padding_10_0 overflow_auto"
        >
          <div v-for="(keywords,p_index) in finalSelectedKeyWordsList" :key="p_index" class="flex_row ">
            <div v-for="(keyword,index) in keywords.keywords" :key="keyword.keyword"
                 class="flex_row ">
              <el-button type="" size="small" :id="`keyword_${p_index}_${index}`">
                <el-button
                    v-for="(text,s_index) in keyword.keyword.split('')"
                    :key="text"
                    size="small"
                    :type="keyword.highlight === s_index?'primary':''"
                    :plain="keyword.highlight === s_index"
                    :style="{marginLeft:'0',border:'none'}"
                >
                  {{ text }}
                </el-button>
              </el-button>
              <span class="w5"></span>
            </div>
          </div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <el-button :disabled="isLoading"
                   size="mini"
                   type=""
                   plain
                   round
                   @click="onModifySelectedKeywordsClick">修 改
        </el-button>
      </div>
    </el-card>
    <div class="h20"></div>
    <!-- 助记新词 -->
    <el-skeleton v-if="isGenerateNewWords" :rows="6" animated/>
    <template v-else>
      <span class="hold_text_color_c0c4cc normal_text_size_14 margin_bottom_5">
        <i class="el-icon-s-open"></i>
        助记合成词
      </span>
      <el-card class=" flex_col" shadow="never" :body-style="{padding:'10px'}">
        <div class=" width_100 flex_row_allcenter border_wrapper padding_0_10">
          <div class=" flex_1 flex_row_allcenter height_100 small_normal_text_size_13 not_main_text_color_909399  padding_10_0 overflow_auto"
          >
            <div v-for="(items,index) in generateNewWords['new_words_result']" :key="index" class="flex_row ">
              <el-dropdown :id="`newword_${index}`" @command="onFinalWordChangeClick">
                <el-button type="primary" plain size="medium">
                  {{ finalWords[index] }}
                  <i v-if="items.length>1" class="el-icon-caret-bottom el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="item in items" :command="{index,item}">{{ item }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <span class="w5"></span>
            </div>
          </div>
          <el-divider direction="vertical"></el-divider>
          <el-button :disabled="isLoading"
                     size="mini"
                     type="primary"
                     round
                     @click="onGenerateNewSentenceClick">生 成
          </el-button>
        </div>
      </el-card>
      <div class="h20"></div>
      <!-- 助记新词 -->
        <span class="hold_text_color_c0c4cc normal_text_size_14 margin_bottom_5">
          <i class="el-icon-s-open"></i>
          助记语句
        </span>
        <el-card v-loading="isGenerateNewSentance" class=" flex_col" shadow="never" :body-style="{padding:'10px'}">
          <div class=" width_100 flex_row_allcenter border_wrapper padding_15">
            {{ this.predictText }}
          </div>
        </el-card>
    </template>
  </div>
</template>
<script>
import http       from '@/libs/http';
import LeaderLine from 'leader-line-new'

export default {
  name: 'NewWordsAndSentance',
  props: {
    currentStep: {
      type: Number,
      required: true,
      default: -1,
    },
    keywordsResult: {
      type: Object,
      required: true,
      default: {
        results: [],
      },
    },
  },
  data() {
    return {
      isLoading: false,
      finalSelectedKeyWordsList: [], // 最后发给后端的关键词二维数组[['keywords':{}],['keywords':{}]]
      isGenerateNewWords: false, //是否在请求生成新词,比较耗时
      isGenerateNewSentance: false, //是否在请求生成新句,比较耗时
      isDrawingLine: false, //是否正在画线
      generateNewWords: {}, // 从关键词中生成的新词
      finalWords: [],
      allLines: [],
      predictText: '',
    };
  },
  watch: {
    currentStep(val) {
      if (val === 2) {
        this.http_getPredict();
      }
    },
  },
  mounted() {
  },
  methods: {
    http_getPredict() {
      this.isLoading = true;
      this.drawLeaderLineForKeywords();
      this.isGenerateNewWords = true;
      this.finalSelectedKeyWordsList = [];
      this.finalSelectedKeyWordsList = this.keywordsResult.results.reduce((pre, cur) => {
        return pre.concat({'keywords': cur['keywords_array']});
      }, []);
      http.post('/generateNewWords', {
        keywords: this.finalSelectedKeyWordsList,
        blur: false,
      }).then(async res => {
        this.isLoading = false;
        await this.waitDrawingFinish();
        this.generateNewWords = res.data;
        this.finalWords = [];
        this.generateNewWords['new_words_result'].forEach(item => {
          this.finalWords.push(item[0]);
        });
        this.http_getPredictText();
        this.isGenerateNewWords = false;
        this.$set(this, 'finalSelectedKeyWordsList', this.generateNewWords['final_selected_keywords']);
        await this.drawLeaderLineForNewwords();
      }).catch(err => {
        this.isGenerateNewWords = false;
        this.isLoading = false;
        console.log(err);
      });
    },
    onModifySelectedKeywordsClick() {
      this.removeAllLines();
      this.$emit('onModifySelectedKeywordsClick');
    },
    delay(_time = 500) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, _time);
      });
    },
    waitDrawingFinish() {
      return new Promise((resolve) => {
        const _int = setInterval(() => {
          if (!this.isDrawingLine) {
            clearInterval(_int);
            resolve();
          }
        }, 1000);
      });
    },
    //
    async drawLeaderLineForKeywords() {
      this.isDrawingLine = true;
      for (const p_index in this.keywordsResult.results) {
        // keywords_array start end
        const keywords = this.keywordsResult.results[p_index]['keywords_array'];
        for (const index in keywords) {
          await this.delay();
          this.drawLeaderLine(`textword_${p_index}_${keywords[index]['start']}`, `keyword_${p_index}_${index}`, 3);
        }
      }
      this.isDrawingLine = false;
    },
    async drawLeaderLineForNewwords() {
      let _start = 0;
      for (const index in this.generateNewWords['pairs']) {
        const pair = this.generateNewWords['pairs'][index];
        await this.delay();
        for (const s_index in pair) {
          this.drawLeaderLine(`keyword_${index}_${_start}`, `newword_${index}`, 2);
          _start = _start + 1;
        }
      }
    },
    // 画线
    drawLeaderLine(start, end, size = 2) {
      const ll = new LeaderLine(
          document.getElementById(start),
          document.getElementById(end),
          {
            size: size,
            startPlugColor: 'rgba(24,144,255,0.25)',
            endPlugColor: 'rgba(24,144,255,0.25)',
            gradient: true,
            startSocket: 'bottom',
            endSocket: 'top',
            startPlug: 'disc',
            endPlug: 'arrow1',
            showEffectName: 'draw',
            hide: true,
            dash: {animation: true},
          },
      );
      this.allLines.push(ll);
      ll.show('draw');
    },
    // 删除线
    removeAllLines() {
      for (const ll of this.allLines) {
        ll.remove();
      }
      this.allLines = [];
    },
    // 点击切换最终词
    onFinalWordChangeClick(data) {
      this.finalWords.splice(data.index, 1, data.item);
    },
    // 点击生成新句子
    onGenerateNewSentenceClick() {
      this.http_getPredictText();
    },
    http_getPredictText() {
      // 生成语句
      this.isGenerateNewSentance = true
      this.predictText = '';
      http.post('/getPredictText', {
        keywords: this.finalWords,
      }).then(res => {
        this.predictText = res.data['predict'];
        this.isGenerateNewSentance = false
      }).catch(err => {
        console.log(err);
        this.isGenerateNewSentance = false
      });
    },
  },
};
</script>
<style scoped>
</style>
