<template>
  <el-container class="height_100 flex_col" style="background:#FDFFFC">
    <el-header class="flex_row_allcenter" style="background:#011627">
      <span class="main_text_size_20" style="color: white"> _Ai助记_</span>
    </el-header>
    <el-main style="padding:0 25px;" class="flex_col flex_1 hidden-xs-only">
      <!--main header-->
      <div class="padding_5 flex_row align_center">
        <span class="w10"></span>
        <div style="width: 350px;">
          <el-carousel height="50px" direction="vertical" :autoplay="true">
            <el-carousel-item v-for="(item,index) in tipsArray" :key="item">
              <div class="width_100 height_100 flex_row_allcenter">
                <span class="medium">{{ `${item}` }}</span>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="width: 200px;">
        </div>
        <div class="w20"></div>
        <span class="flex_1"></span>
        <a-popover title="快捷键说明 : Windows系统使用CTRL键,Mac系统使用Command键" placement="topLeft" arrow-point-at-center>
          <template slot="content">
            <div class="flex_col">
              <a-tag color="blue">CTRL+方向键 : 上一条/下一条</a-tag>
              <div class="h5"></div>
              <a-tag color="blue">CTRL+ENTER : 标记完成</a-tag>
              <div class="h5"></div>
              <a-tag color="blue">CTRL+C : 复制本条结果</a-tag>
              <div class="h5"></div>
              <a-tag color="blue">CTRL+1 : 上一个标题分离规则</a-tag>
              <div class="h5"></div>
              <a-tag color="blue">CTRL+2 : 下一个标题分离规则</a-tag>
              <div class="h5"></div>
              <a-tag color="blue">CTRL+3 : 上一个内容分离规则</a-tag>
              <div class="h5"></div>
              <a-tag color="blue">CTRL+4 : 下一个内容分离规则</a-tag>
              <div class="h5"></div>
            </div>
          </template>
          <a-button type="dashed">
            <a-icon type="calculator"/>
            快捷键
          </a-button>
        </a-popover>
        <span class="w10"></span>
        <a-button type="danger">
          <a-icon type="share-alt"/>
          分享
        </a-button>
      </div>
      <!---->
      <div class="flex_col" style="background: #EDF2F4 ;padding:25px;height: 600px;box-sizing: border-box;">
        <!--1.录入文本-->
        <UserInputContent v-model="userInputData"
                          :current-step="currentStep"
                          @onFisrtStepFinishClick="onFisrtStepFinishClick"
        ></UserInputContent>
        <!-- 2.提取关键词 -->
        <ExtractKeywords :user-input-data="userInputData"
                         :current-step="currentStep"
                         :separeted-user-input-data-array="separetedUserInputDataArray"
                         @onModifyUserInputDataClick="onModifyUserInputDataClick"
                         @onSecondStepFinishClick="onSecondStepFinishClick"

        ></ExtractKeywords>
        <!--  3.展示新词和生成的新句-->
        <NewWordsAndSentance :current-step="currentStep"
                             :keywords-result="keywordsResult"
        @onModifySelectedKeywordsClick="onModifySelectedKeywordsClick"
         ></NewWordsAndSentance>

      </div>
    </el-main>
    <el-footer class="flex_row_allcenter" style="height: 40px;">
      <span class="not_main_text_color_909399 description_text_size_12"> In-House v0.0.1 Alpha</span>
    </el-footer>

  <!--  结果弹框-->
  <!--  <ResultDialog :dialog-visible="isShowResultDialog"/>-->
  </el-container>
</template>
<script>
import {Cons}              from '@/libs/constant';
import ResultDialog        from '@/components/ResultDialog';
import ExtractKeywords     from '@/components/ExtractKeywords';
import UserInputContent    from '@/components/UserInputContent';
import NewWordsAndSentance from '@/components/NewWordsAndSentance';

export default {
  name: 'ProcessText',
  components: {
    NewWordsAndSentance,
    UserInputContent,
    ExtractKeywords,
    },
  data() {
    return {
      tipsArray: Cons.tipsArray,// 循环提示信息列表
      currentStep: 0,
      userInputData: '1.素质教育以提高国民素质为根本宗旨, 2.素质教育是面向全体学生的教育, 3.素质教育是促进学生全面发展的教育, 4.素质教育是促进学生个性发展的教育, 5.素质教育是以培养学生的创新精神和实践能力为重点的教育.',  // 原始输入数据
      separetedUserInputDataArray: [], // 分离后的输入数组列表
      keywordsResult: {
        results: [],
      },
      isShowResultDialog:false
    };
  },
  mounted() {
    console.log('mounted');
    this.initEvent();
  },
  destroyed() {
    console.log('destroyed');
  },
  methods: {
    initEvent() {
      document.onkeydown = (e) => {
        //事件对象兼容
        let evn = e || event || window.event;
        let key = evn.keyCode || evn.which || evn.charCode;
        // 回车键
        if (key === 13) {
          //this.userEnterKeyUp(e);
        }
      };
    }, // 响应用户回车事件
    userEnterKeyUp() {
    },
    // 生成列表数据
    // ›››››››››››››››››››››››››››› 通用方法 ‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹

    // ›››››››››››››››››››››››››››› 组件事件 ‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹

    // ›››››››››››››››››››››››››››› 文本处理 ‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹
    // 录入文本,分段后,点击完成
    onFisrtStepFinishClick(_data) {
      this.separetedUserInputDataArray = _data
      this.currentStep = 1;
    },

    // 选择关键词,点击修改用户输入
    onModifyUserInputDataClick() {
      this.currentStep = 0;
    },
    // 第二步完成
    onSecondStepFinishClick(keywordsResult) {
      this.keywordsResult = keywordsResult
      this.currentStep = 2;

    },
    // 点击修改最后一步中的已选中关键词
    onModifySelectedKeywordsClick() {
      this.currentStep = 1;
    },


  },
};
</script>
<style lang="scss">
@import "../assets/globals";


.el-steps--simple {
  background: transparent !important;
}

.el-textarea__inner {
  border: 1px dashed #DCDFE6 !important;
}

.el-button {
  border: 1px solid #dcdfe659;
}

.el-button--small, .el-button--small.is-round {
  padding: 5px !important;
}

.ant-empty-description {
  color: #c0c4cc;
  font-size: 12px;
}

.seperate-resault-view {
  border: 1px dashed #DCDFE6 !important;
  height: 264px;
  overflow: auto;
}

//keywords
.keywords_view_header_card {
  .el-card__body {
    padding: 0 20px;
  }
}

.border_wrapper {
  border: 1px dashed #DCDFE6;
}

.keywords_content_wrapper {
  overflow: auto;

  .keywords_content_main {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    overflow: auto;

    box-sizing: border-box;
    padding-top: 10px;

    .main_word {
      margin-left: 10px;
      margin-bottom: 10px
    }
  }
}

.new_words_header_card {
  .el-card__header {
    padding: 10px;
  }
}
</style>