'use strict'

const ChinesePunction = [
  '！',
  '“',
  '”',
  '；',
  '《',
  '》',
  '。',
  '、',
  '，',
  '【',
  '】',
  '（',
  '）',
  '？',
  '：'];
const EnglishPunction = [
  '!',
  '"',
  '"',
  ';',
  '<',
  '>',
  '.',
  ',',
  ',',
  '[',
  ']',
  '(',
  ')',
  '?',
  ':'];

const replaceChinese = function(content) {
  ChinesePunction.forEach(function(ele, index) {
    content = content.replace(new RegExp(ele,'g'), EnglishPunction[index]);
  });
  return content;
};

const replaceEnglish = function(content) {
  EnglishPunction.forEach(function(ele, index) {
    content = content.replace(new RegExp(ele,'g'), ChinesePunction[index]);
  });

  return content;
};

module.exports = {
  replaceChinese: replaceChinese,
  replaceEnglish: replaceEnglish,
  EnglishPunction:EnglishPunction
}